import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryCard = (props:any) => {
    const navigate = useNavigate();
    return (
        <div className="category-card" onClick={() => navigate(props.data.link)} >
            <div className="icon-container">
                <img width="50px" height="50px" className="icon-container" src={props.data.icon} alt="arrow"></img>
                <img width="50px" height="50px" className="icon-container" src={props.data.icon2} alt="arrow"></img>
            </div>
            
            <p className="title">{props.data.title}</p>

        </div>
    );
};

export default CategoryCard;
