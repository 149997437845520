import React from "react";
import Country from "../Country";

interface TableProps {
    data: any;
    headers: string[];
}

const DynamicTable: React.FC<TableProps> = ({ data, headers }) => {
    // Extract column headers from the keys of the first object
    //const headers = data.length > 0 ? Object.keys(data[0]) : [];
    

    return (
        <table className="records-table">
            <thead>
                <tr className="row">
                    {headers.map((header) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map(
                    (
                        row: {
                            country: string;
                            flag: string;
                            numberOfCompanies: number;
                            numberOfProducts: number;
                        },
                        index: React.Key | null | undefined
                    ) => (
                        <tr key={index}>
                            <td>
                                <Country flag={row.flag} country={row.country} />
                            </td>
                            <td>{row.numberOfCompanies}</td>
                            <td>{row.numberOfProducts}</td>
                        </tr>
                    )
                )}
            </tbody>
        </table>
    );
};

export default DynamicTable;
