export let president = {
    name: "Mateja Čalušić",
    position: "Ministrstvo za kmetijstvo, gozdarstvo in prehrano RS",
};

export let members = [
    {
        name: "mag. Ervin Kosi",
        position: "Ministrstvo za kmetijstvo, gozdarstvo in prehrano RS",
    },
    {
        name: "Eva Knez",
        position: "Ministrstvo za kmetijstvo, gozdarstvo in prehrano RS",
    },
    {
        name: "Gregor Meterc",
        position: "Ministrstvo za kmetijstvo, gozdarstvo in prehrano RS",
    },
    {
        name: "Ana Le Marechal Kolar",
        position: "Ministrstvo za kmetijstvo, gozdarstvo in prehrano RS",
    },
    {
        name: "Maša Žagar",
        position: "Ministrstvo za kmetijstvo, gozdarstvo in prehrano RS",
    },
    {
        name: "dr. Vida Čadonič Špelič",
        position: "DZ, Odbor za kmetijstvo",
    },
    {
        name: "Jože Novak",
        position: "Ministrstvo za naravna vire in prostor RS",
    },
    {
        name: "Matjaž Han",
        position: "Ministrstvo za gospodarstvo, turizem in šport",
    },
    {
        name: "mag. Dejan Židan",
        position: "Ministrstvo za gospodarstvo, turizem in šport",
    },
    {
        name: "mag. Marko Matjašič",
        position: "Slovenski državni gozdovi",
    },
    {
        name: "dr. Aleš Kadunc",
        position: "Slovenski državni gozdovi",
    },
    {
        name: "Gregor Danev",
        position: "Zavod za gozdove Slovenije",
    },
    {
        name: "Roman Žveglič",
        position: "Kmetijsko gozdarska zbornica Slovenije",
    },
    {
        name: "mag. Janez Pirc",
        position: "Kmetijsko gozdarska zbornica Slovenije",
    },
    {
        name: "Danilo Potokar",
        position: "Kmetijsko gozdarska zbornica Slovenije",
    },
    {
        name: "Robert Peklaj",
        position: "Kmetijsko gozdarska zbornica Slovenije",
    },
    {
        name: "Jernej Vrtačnik",
        position: "Kmetijsko gozdarska zbornica Slovenije",
    },
    {
        name: "Irena Kropf",
        position: "KGZS, Kmetijsko gozdarski zavod Maribor",
    },
    {
        name: "dr. Stanko Kapun",
        position: "KGZS, Kmetijsko gozdarski zavod Murska Sobota",
    },
    {
        name: "Marjan Špur",
        position: "KGZS, Kmetijsko gozdarski zavod Murska Sobota",
    },
    {
        name: "Andrej Rebernišek",
        position: "KGZS, Kmetijsko gozdarski zavod Ptuj",
    },
    {
        name: "Jože Smolinger",
        position: "KGZS, Kmetijsko gozdarski zavod Ptuj",
    },
    {
        name: "Borut Florjančič",
        position: "Zadružna zveza Slovenije z.o.o.",
    },
    {
        name: "Alenka Marjetič Žnider",
        position: "Zadružna zveza Slovenije z.o.o.",
    },
    {
        name: "dr. Andrej Simončič",
        position: "Kmetijski inštitut Slovenije",
    },
    {
        name: "Tibor Šimonka",
        position: "Gospodarska zbornica Slovenije",
    },
    {
        name: "Vesna Nahtigal",
        position: "Gospodarska zbornica Slovenije",
    },
    {
        name: "Robert Grah",
        position: "Pomurska gospodarska zbornica",
    },
    {
        name: "dr. Tatjana Zagorc",
        position: "GZS Zbornica kmetijskih in živilskih podjetij",
    },
    {
        name: "mag. Anita Jakuš",
        position: "GZS Zbornica kmetijskih in živilskih podjetij",
    },
    {
        name: "prof. dr. Marina Pintar",
        position: "Univerza v Ljubljani, Biotehniška fakulteta",
    },
    {
        name: "prof. dr. Mojca Korošec",
        position: "Univerza v Ljubljani, Biotehniška fakulteta",
    },
    {
        name: "Mojca Voljč",
        position:
            "Univerza v Ljubljani, Biotehniška fakulteta, Oddelek za zootehniko",
    },
    {
        name: "mag. Danijela Bojkovski",
        position:
            "Univerza v Ljubljani, Biotehniška fakulteta, Oddelek za zootehniko",
    },
    {
        name: "dr. Metka Žan",
        position:
            "Univerza v Ljubljani, Biotehniška fakulteta, Oddelek za zootehniko",
    },
    {
        name: "prof. dr. Breda Jakovac Strajn",
        position: "Univerza v Ljubljani, Veterinarska fakulteta",
    },
    {
        name: "dr. Matjaž Mesarič",
        position: "Univerza v Ljubljani, Veterinarska fakulteta",
    },
    {
        name: "prof. dr. Branko Kramberger",
        position:
            "Univerza v Mariboru, Fakulteta za kmetijstvo in biosistemske vede",
    },
    {
        name: "prof. dr. Martina Bavec",
        position:
            "Univerza v Mariboru, Fakulteta za kmetijstvo in biosistemske vede",
    },
    {
        name: "Marijan Pogačnik",
        position: "Konzorcij biotehniških šol",
    },
    {
        name: "Simona Potočnik",
        position: "Biotehniška šola Rakičan",
    },
    {
        name: "Marjana Cvirn",
        position: "Zveza društev rejcev drobnice Slovenije",
    },
    {
        name: "Boštjan Noč",
        position: "Čebelarska zveza Slovenije",
    },
    {
        name: "Lidija Senič",
        position: "Čebelarska zveza Slovenije",
    },
    {
        name: "Bojan Cizej",
        position: "Inštitut za hmeljarstvo in pivovarstvo Slovenije",
    },
    {
        name: "Eva Golob",
        position: "Zveza slovenske podeželske mladine",
    },
    {
        name: "Marjan Colja",
        position: "Vinska družba Slovenije, d.o.o.",
    },
    {
        name: "Boštjan Zidar",
        position: "Vinakoper d.o.o.",
    },
    {
        name: "mag. Tatjana Vošinek Pucer",
        position: "Kobilarna Lipica",
    },
    {
        name: "Irena Ule",
        position: "Zveza kmetic Slovenije",
    },
    {
        name: "Marija Marinček",
        position: "Zveza društev ekoloških kmetov Slovenije",
    },
    {
        name: "Aleš Fister",
        position: "Zveza društev ekoloških kmetov Slovenije",
    },
    {
        name: "Urška Mauko Tuš",
        position: "Občina Gornja Radgona",
    },
    {
        name: "Blaž	Cvar",
        position: "Obrtno-podjetniška zbornica Slovenije",
    },
    {
        name: "Dominik Sava Černjak",
        position: "Turistična zveza Slovenije",
    },
    {
        name: "Karmen 	Burger",
        position: "Turistična zveza Slovenije",
    },
    {
        name: "Matija Vimpolšek",
        position: "Združenje turističnih kmetij Slovenije",
    },
    {
        name: "Tevž	Tavčar",
        position: "ČZD Kmečki glas, d.o.o.",
    },
    {
        name: "Janez Erjavec",
        position: "Pomurski sejem",
    },
    {
        name: "Janez Erjavec",
        position: "Pomurski sejem",
    },
    {
        name: "Boris Nicolas Erjavec	",
        position: "Pomurski sejem",
    },
    {
        name: "Staš	Sukič",
        position: "Pomurski sejem",
    },
    {
        name: "Sandra Sedmak",
        position: "Pomurski sejem",
    },
    {
        name: "Jana	Dimec",
        position: "Pomurski sejem",
    },
    {
        name: "Miran Mate",
        position: "Pomurski sejem",
    },
];
