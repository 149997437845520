import React, { useEffect, useState } from 'react'

const BackToTop = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100 ){
                setBackToTopButton(true);
            }else{
                setBackToTopButton(false);
            }
        })
    }, [])

    const scrollUp = () =>{
        window.scrollTo({
            top:0,
            behavior:"smooth",
        })
        setBackToTopButton(false);
    }

    return (
        <div>
            {backToTopButton && 
                <button className='toTop' onClick={scrollUp} aria-labelledby="navigate-up"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="white" d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg></button>
            }
        </div>
    )
}

export default BackToTop
