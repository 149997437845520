import React from "react";

interface MemberProps {
    name: string;
    position: string;
}

const Member: React.FC<MemberProps> = ({ name, position }) => {
    return (
        <div className="member">
            <p className="name">{name}</p>
            <p className="position">{position}</p>
        </div>
    );
};

export default Member;