import React from "react";

interface StatsProps {
    data: any;
}
const Stats: React.FC<StatsProps> = ({ data }) => {
    return (
        <div className="stats">
            <div className="stats-item">
                <p className="stats-item-number">
                    {data.total.numberOfProducts}
                </p>
                <p className="stats-item-title">Prijavljenih izdelkov</p>
            </div>
            <div className="stats-item">
                <p className="stats-item-number">
                    {data.total.numberOfAwards}
                </p>
                <p className="stats-item-title">Nagrajenih izdelkov</p>
            </div>
            <div className="stats-item">
                <p className="stats-item-number">
                    {data.total.notAwarded}
                </p>
                <p className="stats-item-title">Nenagrajenih izdelkov</p>
            </div>
            <div className="stats-item">
                <p className="stats-item-number">
                    {data.total.eliminated}
                </p>
                <p className="stats-item-title">Izločenih izdelkov</p>
            </div>
        </div>
    );
};

export default Stats;
