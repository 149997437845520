import React from "react";

interface TwoDTableProps {
    data: any;
}


const TwoDTable: React.FC<TwoDTableProps> = ({ data }) => {
    return (
        <table className="records-table">
            <thead>
                <tr className="row">
                    {data.headers.map((header: string) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.rows.map((row: any, index: number) => (
                    <tr key={index}>
                        {row.map((value: any, index: number) => (
                            <td key={index}>{value}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TwoDTable;
