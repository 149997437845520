import React, {useEffect, useState} from "react";

import titleImg from '../../image/ocenjevanja-kakovosti-logo-2x.png';
import arrow from '../../image/arrow.png';
import blackArrow from '../../image/black-arrow.png';
import { useLocation } from "react-router-dom";
import HeaderMobile from "./HeaderMobile";

const Header = () => {
    const location = useLocation();

    const [mobileNavigationShown, setMobileNavigationShown] = useState(false);
    useEffect(() => {
        
        window.scrollTo(0, 0);
    }, [location]);
    return (
    <div>
        <div className="header">
            <div className='banner'>
        
                <div className='banner-section'>
                    <a className='banner-el' href="https://www.pomurski-sejem.si/" target="_blank">Pomurski sejem</a>
                    <a className='banner-el' href="https://www.ocenjevanja.pomurski-sejem.si/program-ocenjevanj/" target="_blank">Sejem AGRA</a>
                    <a className='banner-el' href="https://www.sejem-agra.si/za-razstavljalce/projektni-tim/" target="_blank">Kontakt</a>
                </div>

                <div className='banner-section2'>
                    <div className='banner-el banner-el-hide'>Pon-Pet 08.00 - 16.00</div>
                    <div className='banner-el banner-el-hide' >
                        <svg width="12px" height="12px" preserveAspectRatio="xMidYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#FAFAFA" d="M347.1 24.6c7.7-18.6 28-28.5 47.4-23.2l88 24C499.9 30.2 512 46 512 64c0 247.4-200.6 448-448 448c-18 0-33.8-12.1-38.6-29.5l-24-88c-5.3-19.4 4.6-39.7 23.2-47.4l96-40c16.3-6.8 35.2-2.1 46.3 11.6L207.3 368c70.4-33.3 127.4-90.3 160.7-160.7L318.7 167c-13.7-11.2-18.4-30-11.6-46.3l40-96z"/></svg>
                        02 564 2 100
                    </div>
                </div>
            </div>

            <div className="header-img">
                <img src={titleImg} width="125" height="39" alt="titleImg"></img>
                <svg className="m-show" onClick={() => setMobileNavigationShown(true)} height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>
            </div>

            <div className="navigation m-hide">
                <ul className='nav-section'>

                    <li ><a className='nav-el' href="https://www.ocenjevanja.pomurski-sejem.si/" >Domov</a></li>
                    <li ><a className='nav-el' href="https://www.ocenjevanja.pomurski-sejem.si/program-ocenjevanj/" >Program ocenjevanj</a></li>
                    <li ><a className='nav-el active' href="https://katalog.sejem-agra.si/" >Katalog ocenjevanj</a></li>
                    <li className="dropdown"><a className='nav-el' href="#" >Rezultati ocenjevanj</a>
                        <ul className="dropdown-content" >
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span>2024</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2024/07/Katalog-sok.pdf" target="_blank"><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2024/07/MesoABCD-2024.pdf" target="_blank"><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2024/07/Katalog-mleko-2024.pdf" target="_blank"><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span  >2023</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/08/Katalog-med-2023.pdf" target="_blank"><span  >Ocenjevanje medu z mednarodno udeležbo</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/07/Katalog-medenih-pijac2023.pdf" target="_blank"><span  >Ocenjevanje medenih pijač</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/06/Katalog-sokovi-2023.pdf" target="_blank"><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/06/Katalog-meso-ABCD-2023.pdf" target="_blank"><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/06/Katalog-mleko-2023-1.pdf" target="_blank"><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span  >2022</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2022/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2022/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sokov-pijac-in-vod-2022/" ><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2022/" ><span  >Ocenjevanje medu z mednarodno udeležbo</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-medenih-pijac-2022/" ><span  >Mednarodno ocenjevanje medenih pijač 2022</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span  >2021</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2021/11/Katalog-cebelji-pridelki-2021.pdf" ><span  >Živilski izdelki s čebeljimi pridelki</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2021/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2021</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/program-ocenjevanj/mednarodno-ocenjevanje-medenih-pijac/" ><span  >Mednarodno ocenjevanje medenih pijač</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sokov-pijac-in-vod-2021/" ><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod 2021</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2021/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2021</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2021/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2021</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span  >2020</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="http://www.vinoslovenija.si/rezultati-2020/" target="_blank"><span  >Vino Slovenija Gornja Radgona 2020</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/odprti-natecaj-slovenski-oskar-za-embalazo/" ><span  >Odprti natečaj Slovenski oskar za embalažo 2024</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2020/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2020</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-medenih-pijac-2020/" ><span  >Mednarodno ocenjevanje medenih pijač 2020</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sokov-pijac-in-vod-2020/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2020</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2020/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2020</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2020/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2020</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span  >2019</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sadnih-sokov-pijac-in-vod-2019/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2019</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2019/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2019</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2019/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2019</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2019/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2019</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span  >2018</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2018/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2018</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2018/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2018</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sadnih-sokov-pijac-in-vod-2018/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2018</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2018/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2018</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" ><span  >2017</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2017/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2017</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2017/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2017</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sadnih-sokov-pijac-in-vod-2017/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2017</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2017/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2017</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/salamiada-2017.pdf" ><span  >Ocenjevanje divjačinskih salam v sklopu sejma LOV (PDF)</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/katalog-ocenjevanj-2017-dodatek.pdf" ><span  >Ocenjevanje kmetijske mehanizacije in opreme (PDF)</span></a></li>
                                </ul>
                            </li>
                            <li className='toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow'><a href="#" style={{borderBottom: "none"}} ><span  >2016</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a>
                                <ul className="sub-menu">
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/rezultati-sveze-meso-2016.pdf" ><span  >Mednarodno ocenjevanje svežega mesa (PDF)</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/mesni-izdelki-katalog-2016-slo.pdf" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov (PDF)</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2016/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2016</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/sokovi-katalog-2016-slo.pdf" ><span  >Mednarodno ocenjevanje sokov, pijač in vod (PDF)</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2016/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2016</span></a></li>
                                    <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/katalog-ocenjevanj-2016-dodatek.pdf" ><span  >Ocenjevanje kmetijske mehanizacije in opreme (PDF)</span></a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="dropdown"><a className='nav-el' href="https://drive.google.com/drive/folders/0B7MPFY68S8nPVFZ0NGRiWk5MSGc" >Medalje ocenjevanj</a>
                        <ul className="dropdown-content">
                            <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1BkCcnuV3XWaQKlz_0V4OXf8wTyK6SQ8i?usp=sharing" target="_blank"><span  >2024</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a></li>
                            <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1zwZ0ryNAhzc5V-w1y-PQgVgS8Qx-itfY?usp=sharing" target="_blank"><span  >2023</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a></li>
                            <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1IbZpQaH4OYIoyRdUKa_fGgj8gpU8lCG8?usp=sharing" target="_blank"><span  >2022</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a></li>
                            <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1N-OjRd9dTAQHqDe3poxJxNTghIuzdmyp?usp=sharing" target="_blank"><span  >2021</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a></li>
                            <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/12C2SuJftLaRRqHUYBC7KbKUMN1x-BnzD?usp=sharing" target="_blank"><span  >2020</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a></li>
                            <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1txOIuYNqiYwdzKfIrPo1ZorRDaMFlTF8?usp=sharing" target="_blank"><span  >2019</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a></li>
                            <li className='toggle_arrow'><a style={{borderBottom: "none"}}href="https://drive.google.com/drive/folders/1u-Gi7uJaySA5aIWIgsIzi9Rqq8EL3Ecs?usp=sharing" target="_blank"><span  >2018</span><span><img src={arrow} width="15" height="15" alt="arrow"/><img src={blackArrow} width="15" height="15" alt="arrow"/></span></a></li>
                        </ul>
                    </li>
                    {/*<li ><a  className='nav-el' href="https://www.ocenjevanja.pomurski-sejem.si/en/" >
                        <span  >
                            <img className="wpml-ls-flag" src="https://www.ocenjevanja.pomurski-sejem.si/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png" alt="Angleščina" />
                        </span></a>
                    </li>
                    <li ><a  className='nav-el' href="https://www.ocenjevanja.pomurski-sejem.si/de/" >
                        <span  >
                            <img className="wpml-ls-flag" src="https://www.ocenjevanja.pomurski-sejem.si/wp-content/plugins/sitepress-multilingual-cms/res/flags/de.png" alt="Nemščina" />
                        </span></a>
                    </li>*/}

                </ul>
            
            
            </div>


            {/* Mobile navigation */}

            {mobileNavigationShown && <HeaderMobile close={() => setMobileNavigationShown(false)}/>}
            
        </div>

    
    </div>);
};

export default Header;
