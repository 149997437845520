import React, { useEffect } from "react";
import TableSection from "./TableSection";
import MainSection from "./ImageSection";
import { backGroundTitle, getTableData } from "./Data/dataCategories";
import { useNavigate } from "react-router-dom";
import TableOscar from "./Oscar/TableOscar";

const RespectiveCategory = () => {
    const [data, setData] = React.useState<any>([]);
    let url = window.location.pathname;
    const navigate = useNavigate();

    let getPath = getTableData(url);

    if (getPath == "sokovi"){
        getPath = ["sokovi","pijace"]
    }

    useEffect(() => {


        fetch("/api/v1/awards", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                years: 2024,
                categories: getPath
            }),
        })
            .then((resp) => resp.json())
            .then((awards) => {
                if (!awards.success) {
                    throw awards.reason;
                }
                setData(awards.response);
                console.log("getting awards", awards);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    //console.log("data", data);

    return (
        <div>
            <MainSection title={backGroundTitle(url)} year={"2024"} />

            {/* SEARCH SECTION */}
            <div className="searchSection">
                <div className="about">
                    <a href="/">
                        <svg
                            width="10"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                fill="#F49F21"
                                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                            />
                        </svg>{" "}
                        Nazaj na Katalog Nagrad
                    </a>

                    <div className="about">
                        <button
                            className="btn-secondary add-space"
                            onClick={() => navigate(url + "/record")}
                            aria-labelledby="records"
                        >
                            ZAPISNIK
                        </button>
                        <button
                            className="btn-secondary"
                            onClick={() => navigate(url + "/commentary")}
                            aria-labelledby="committee"
                        >
                            Komentar ocenjevalne komisije
                        </button>
                    </div>
                </div>

                <TableSection category={getPath} data={data} />
                
            </div>
        </div>
    );
};

export default RespectiveCategory;
