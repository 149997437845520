export let articles = [
    {
        company: "",
        title: "Visoki jubileji šampionske kakovosti AGRA",
        author: "Predsednik uprave Pomurskega sejma Janez Erjavec",
        content: [
            "Letošnje leto je leto velikih športnih tekmovanj. Slovenske športnice in športniki so najprej dosegali odlične rezultate v smučanju, skokih, nato v slalomu na divjih vodah, plezanju, pri odbojki, na kolesarskih tourih, tekmah svetovnega prvenstva v motokrosu … Poletna vrhunca sta bila zagotovo evropsko prvenstvo v nogometu in olimpijske igre v Parizu. Vsem njim in odličnim tujim športnicam in športnikom, s katerimi so se merili naši, se lahko le globoko priklonimo in jim čestitamo.",
            "V senci športnih uspehov pa so tekmovali tudi pridelovalci, rejci, proizvajalci hrane, vsega tistega, kar je najpomembnejše za preživetje človeštva. Tudi oni so imeli svoje velike olimpijade pod okriljem sejma AGRA 2024. Ta je konec avgusta veliki oder za razglasitev in nagraditev najboljših v tekmovanjih z naravo in neizprosno svetovno konkurenco.",
            "Letošnje leto šampionske kakovosti sejma AGRA je tudi leto jubilejev njenih strokovnih ocenjevanj. S 5. ponovitvijo je šele dobro shodilo mednarodno ocenjevanje medenih pijač. Štiridesetega praznuje Slovenski oskar za embalažo, 45-ega Mednarodno ocenjevanje mesa in mesnih izdelkov in kar 50. jubilej najstarejše ocenjevanje kakovosti pod okriljem sejma AGRA, Odprto državno ocenjevanje Vino Slovenija Gornja Radgona. Dokaz vitalnosti teh ocenjevanj je tržna uspešnost medalj sejma AGRA, s katerimi proizvajalci označujejo svoje izdelke v oglaševanju in na trgovskih policah, saj veljajo za nepogrešljivo merilo kakovosti tako za proizvajalce kot tudi za potrošnike.",
            "Pa se podrobneje ozrimo le v tiste z bolj okroglimi vrstnimi številkami... 5. mednarodno ocenjevanje medenih pijač je pritegnilo 13 slovenskih čebelarjev oz. podjetij s 16 vzorci medenih pijač. Na 45. mednarodnem ocenjevanju mesa in mesnih izdelkov je sodelovalo 44 podjetij s 505 izdelki iz Slovenije in Hrvaške. 40. Slovenski oskar za embalažo je privabil 8 slovenskih prijaviteljev s trinajstimi modeli embalaže. Z največ vzorci se lahko pohvali starosta med našimi ocenjevanji kakovosti, 50. odprto državno ocenjevanje vin “Vino Slovenija Gornja Radgona”. Skupaj s 14. odprtim državnim ocenjevanje BIO vin je pritegnilo kar 148 vinarjev s 651 vzorci iz Slovenije, Avstrije, Hrvaške in Bolgarije, ki je letošnja država partnerica sejma AGRA.",
            "Odlična je bila tudi udeležba na 38. mednarodnem ocenjevanju mleka in mlečnih izdelkov, na katerem je sodelovalo 26 podjetij s 174 izdelki iz Slovenije, Hrvaške in Avstrije ter na 28. mednarodnem ocenjevanju sokov, brezalkoholnih pijač in embaliranih vod z 32 podjetji in 97 izdelki iz Slovenije, Hrvaške in Madžarske. Skupaj s 23. ocenjevanjem medu z mednarodno udeležbo, ki je privabilo 88 čebelarjev oz. podjetij s 142 vzorci in 42. mednarodnim ocenjevanjem kmetijske mehanizacije in opreme je proslavilo kakovost 62. mednarodnega kmetijsko-živilskega sejma AGRA okrog 400 sodelujočih s preko 1.600 izdelki.",
            "Čestitamo vsem, ki ste sodelovali. Na olimpijado kakovosti AGRA 2024 ste poslali najboljše, kar ste pridelali in izdelali. Še posebej pa seveda čestitamo prejemnikom najbolje ocenjenih izdelkov, ki jih krasijo naslovi Šampion, posebna priznanja, velike zlate, zlate, srebrne in bronaste medalje. Članicam in članom strokovnih komisij, ki ste prepoznali in nagradili vrhunsko kakovost pa se zahvaljujemo za prefinjen okus, ostro oko, pretanjen dotik, dolgoletne izkušnje, visoko strokovnost in odlično sodelovanje.",
        ],
    },
];
