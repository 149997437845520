import React, { HTMLProps, useState } from 'react'
import arrow from '../../image/arrow.png';
import blackArrow from '../../image/black-arrow.png';

const HeaderMobile = (props: any) => {
    const [active, setActive] = useState<string>("");

  return (
    <div className="navigation-mobile">
        <div className='mobile-exit'>
           <svg style={{padding:"15px"}} onClick={() => props.close()} width="32px" height="32px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="white" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </div>
        <ul className='nav-section'>

            <li ><a className='nav-el' href="https://www.ocenjevanja.pomurski-sejem.si/" >Domov</a></li>
            <li ><a className='nav-el active' href="https://www.ocenjevanja.pomurski-sejem.si/program-ocenjevanj/" >Program ocenjevanj</a></li>
            <li ><a className='nav-el active' href="https://katalog.sejem-agra.si" >Katalog ocenjevanj</a></li>
            <li className={"dropdown " + (active.includes("rez") ? "active": "")} ><a onClick={() => setActive(active.includes("rez") ? "": "rez")} className='nav-el' href="#" >Rezultati ocenjevanj <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="white" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg></a>
                <ul className="dropdown-content" >
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2024") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2024") ? "rez": "rez.2024")}  href="#" ><span>2024</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2024/07/Katalog-sok.pdf" target="_blank"><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2024/07/MesoABCD-2024.pdf" target="_blank"><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2024/07/Katalog-mleko-2024.pdf" target="_blank"><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2023") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2023") ? "rez": "rez.2023")}href="#" ><span  >2023</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/08/Katalog-med-2023.pdf" target="_blank"><span  >Ocenjevanje medu z mednarodno udeležbo</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/07/Katalog-medenih-pijac2023.pdf" target="_blank"><span  >Ocenjevanje medenih pijač</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/06/Katalog-sokovi-2023.pdf" target="_blank"><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/06/Katalog-meso-ABCD-2023.pdf" target="_blank"><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2023/06/Katalog-mleko-2023-1.pdf" target="_blank"><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2022") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2022") ? "rez": "rez.2022")}href="#" ><span  >2022</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2022/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2022/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sokov-pijac-in-vod-2022/" ><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2022/" ><span  >Ocenjevanje medu z mednarodno udeležbo</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-medenih-pijac-2022/" ><span  >Mednarodno ocenjevanje medenih pijač 2022</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2021") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2021") ? "rez": "rez.2021")} href="#" ><span  >2021</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2021/11/Katalog-cebelji-pridelki-2021.pdf" ><span  >Živilski izdelki s čebeljimi pridelki</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2021/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2021</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/program-ocenjevanj/mednarodno-ocenjevanje-medenih-pijac/" ><span  >Mednarodno ocenjevanje medenih pijač</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sokov-pijac-in-vod-2021/" ><span  >Mednarodno ocenjevanje sokov, brezalkoholnih pijač in embaliranih vod 2021</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2021/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2021</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2021/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2021</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2020") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2020") ? "rez": "rez.2020")} href="#" ><span  >2020</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="http://www.vinoslovenija.si/rezultati-2020/" target="_blank"><span  >Vino Slovenija Gornja Radgona 2020</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/odprti-natecaj-slovenski-oskar-za-embalazo/" ><span  >Odprti natečaj Slovenski oskar za embalažo 2024</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2020/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2020</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-medenih-pijac-2020/" ><span  >Mednarodno ocenjevanje medenih pijač 2020</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sokov-pijac-in-vod-2020/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2020</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2020/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2020</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2020/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2020</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2019") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2019") ? "rez": "rez.2019")} href="#" ><span  >2019</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sadnih-sokov-pijac-in-vod-2019/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2019</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2019/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2019</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2019/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2019</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2019/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2019</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2018") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2018") ? "rez": "rez.2018")} href="#" ><span  >2018</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2018/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2018</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2018/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2018</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sadnih-sokov-pijac-in-vod-2018/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2018</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2018/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2018</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2017") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2017") ? "rez": "rez.2017")} href="#" ><span  >2017</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mesa-in-mesnih-izdelkov-2017/" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov 2017</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2017/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2017</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-sadnih-sokov-pijac-in-vod-2017/" ><span  >Mednarodno ocenjevanje sokov, pijač in vod 2017</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2017/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2017</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/salamiada-2017.pdf" ><span  >Ocenjevanje divjačinskih salam v sklopu sejma LOV (PDF)</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/katalog-ocenjevanj-2017-dodatek.pdf" ><span  >Ocenjevanje kmetijske mehanizacije in opreme (PDF)</span></a></li>
                        </ul>
                    </li>
                    <li className={"toggle_arrow menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow " + (active.includes("rez.2016") ? "active": "")} ><a onClick={() => setActive(active.includes("rez.2016") ? "rez": "rez.2016")} href="#" style={{borderBottom: "none"}} ><span  >2016</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a>
                        <ul className="sub-menu">
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/rezultati-sveze-meso-2016.pdf" ><span  >Mednarodno ocenjevanje svežega mesa (PDF)</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/mesni-izdelki-katalog-2016-slo.pdf" ><span  >Mednarodno ocenjevanje mesa in mesnih izdelkov (PDF)</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/mednarodno-ocenjevanje-mleka-in-mlecnih-izdelkov-gornja-radgona-2016/" ><span  >Mednarodno ocenjevanje mleka in mlečnih izdelkov 2016</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/sokovi-katalog-2016-slo.pdf" ><span  >Mednarodno ocenjevanje sokov, pijač in vod (PDF)</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/rezultati-ocenjevanj/ocenjevanje-medu-z-mednarodno-udelezbo-2016/" ><span  >Ocenjevanje medu z mednarodno udeležbo 2016</span></a></li>
                            <li ><a href="https://www.ocenjevanja.pomurski-sejem.si/wp-content/uploads/sites/13/2018/03/katalog-ocenjevanj-2016-dodatek.pdf" ><span  >Ocenjevanje kmetijske mehanizacije in opreme (PDF)</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li className={"dropdown " + (active.includes("medalje") ? "active": "")} ><a onClick={() => setActive(active.includes("medalje") ? "": "medalje")} className='nav-el' >Medalje ocenjevanj <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="white" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg></a>
                <ul className="dropdown-content">
                    <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1BkCcnuV3XWaQKlz_0V4OXf8wTyK6SQ8i?usp=sharing" target="_blank"><span  >2024</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a></li>
                    <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1zwZ0ryNAhzc5V-w1y-PQgVgS8Qx-itfY?usp=sharing" target="_blank"><span  >2023</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a></li>
                    <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1IbZpQaH4OYIoyRdUKa_fGgj8gpU8lCG8?usp=sharing" target="_blank"><span  >2022</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a></li>
                    <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1N-OjRd9dTAQHqDe3poxJxNTghIuzdmyp?usp=sharing" target="_blank"><span  >2021</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a></li>
                    <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/12C2SuJftLaRRqHUYBC7KbKUMN1x-BnzD?usp=sharing" target="_blank"><span  >2020</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a></li>
                    <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1txOIuYNqiYwdzKfIrPo1ZorRDaMFlTF8?usp=sharing" target="_blank"><span  >2019</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a></li>
                    <li className='toggle_arrow'><a href="https://drive.google.com/drive/folders/1u-Gi7uJaySA5aIWIgsIzi9Rqq8EL3Ecs?usp=sharing" target="_blank"><span  >2018</span><span><img src={arrow} width="15" height="15" alt="arrow"/></span></a></li>
                </ul>
            </li>
            {/*<li ><a  className='nav-el' href="https://www.ocenjevanja.pomurski-sejem.si/en/" >
                <span  >
                    <img className="wpml-ls-flag" src="https://www.ocenjevanja.pomurski-sejem.si/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png" alt="Angleščina" />
                </span></a>
            </li>
            <li ><a  className='nav-el' href="https://www.ocenjevanja.pomurski-sejem.si/de/" >
                <span  >
                    <img className="wpml-ls-flag" src="https://www.ocenjevanja.pomurski-sejem.si/wp-content/plugins/sitepress-multilingual-cms/res/flags/de.png" alt="Nemščina" />
                </span></a>
            </li>*/}

        </ul>
    
    
    </div>
  )
}

export default HeaderMobile;
