import { members, president } from "../ProgramCouncil/data";

export let records = {
    "mlecni-izdelki": {
        foreword:
            "o 38. mednarodnem ocenjevanju mleka in mlečnih izdelkov v okviru 62. mednarodnega kmetijsko-živilskega sejma Agra v Gornji Radgoni. Ocenjevanje je potekalo od 28. maja do 31. maja 2024 na Pomurskem sejmu v Gornji Radgoni.",
        president: [
            {
                name: "izr. prof. dr. Andreja Čanžek Majhenič",
                position:
                    "Biotehniška fakulteta, Katedra za mlekarstvo, Ljubljana",
            },
        ],
        members: [
            {
                name: "asist. dr. Diana Paveljšek",
                position:
                    "Biotehniška fakulteta, Katedra za mlekarstvo, Ljubljana",
            },
            {
                name: "prof. dr. sc. Jasmina Havranek",
                position: "Agronomski fakultet, Zavod za mljekarstvo, Zagreb",
            },
            {
                name: "prof. dr. sc. Neven Antunac",
                position: "Agronomski fakultet, Zavod za mljekarstvo, Zagreb",
            },
            {
                name: "Petra Osterman, univ. dipl. inž. živ. teh.",
                position: "Viški vrtci, Ljubljana",
            },
            {
                name: "Stanka Podkrajšek, univ. dipl. inž. živ. teh.",
                position:
                    "Biotehniška fakulteta, Inštitut za mlekarstvo Ljubljana",
            },
        ],
        technicalLeader: {
            name: "Nika Lekan",
            position:
                "Biotehniška fakulteta, Inštitut za mlekarstvo in probiotike, Domžale",
        },
        statistics: [
            {
                description:
                    "Na ocenjevanju je sodelovalo 26 podjetij s 174 izdelki:",
                collaborators: [
                    {
                        flag: "SI",
                        country: "Slovenija",
                        numberOfCompanies: 22,
                        numberOfProducts: 120,
                    },
                    {
                        flag: "HR",
                        country: "Hrvaška",
                        numberOfCompanies: 3,
                        numberOfProducts: 31,
                    },
                    {
                        flag: "AT",
                        country: "Avstrija",
                        numberOfCompanies: 1,
                        numberOfProducts: 23,
                    },
                ],
                total: {
                    numberOfProducts: 174,
                    numberOfAwards: 169,
                    notAwarded: 4,
                    eliminated: 1,
                },
                awards: {
                    titles: [
                        "Šampion",
                        "Velika zlata medalja",
                        "Zlata medalja",
                        "Srebrna medalja",
                        "Bronasta medalja",
                    ],
                    numbers: [10, 42, 27, 63, 27],
                },
                extras: {
                    title: "Šampioni kakovosti",
                    description:
                        "Izdelek, ki je tri leta zaporedoma dobil veliko zlato medaljo in v svoji skupini po številu točk dosegel najmanj tretji rezultat.",
                    awards: [
                        {
                            product: "Kärntnermilch Blütenkäse",
                            company: "Kärntnermilch reg. Gen.m.b.H.",
                        },
                        {
                            product: "Bio Wiesenmilch Almkäse 50% F.i.T.",
                            company: "Kärntnermilch reg. Gen.m.b.H.",
                        },
                        {
                            product: "Bio Wiesenmilch Rahmkäse 55% F.i.T.",
                            company: "Kärntnermilch reg. Gen.m.b.H.",
                        },
                        {
                            product: "Kärntnermilch Kräuterbutter",
                            company: "Kärntnermilch reg. Gen.m.b.H.",
                        },
                        {
                            product: "Kärntnermilch Steinpilzbutter",
                            company: "Kärntnermilch reg. Gen.m.b.H.",
                        },
                        {
                            product: "Štrukelj s temno čokolado in malinami",
                            company:
                                "Moji štruklji Slovenije, Peter Vogelnik s.p.",
                        },
                        {
                            product: "Ementalec",
                            company: "Pomurske mlekarne d.d.",
                        },
                        {
                            product: "Mleko v prahu",
                            company: "Pomurske mlekarne d.d.",
                        },
                        {
                            product: "Mliječni puding vanilija",
                            company: "Vindija d.d. Varaždin",
                        },
                        {
                            product: "'z bregov UHT Kozje mlijeko",
                            company: "Vindija d.d. Varaždin",
                        },
                    ],
                    specialAward: {
                        title: "Posebno priznanje za doseženo izjemno kakovost je komisija soglasno podelila:",
                        description:
                            "celotnemu programu ocenjenih izdelkov proizvajalca Kärntnermilch reg. Gen.m.b.H. iz Avstrije. Mlekarna, ki se ponaša s skoraj 100-letno tradicijo (ustanovljena 1928), je s svojimi izdelki trenutno vodilna na koroškem tržišču. Tudi dolgoletna tradicija ni uspavala mlekarne oz. njenih mlekarjev, ampak še vedno, vsako leto, s svojimi izdelki sodelujejo na mednarodnem ocenjevanju v Gornji Radgoni. Ni jim izpod časti, da kljub tradiciji še vedno preverjajo kakovost svojih izdelkov na takih priznanih ocenjevanjih kot je Agra, kjer se dobro zavedajo visokega nivoja ocenjevanja, strokovnosti, doslednosti, a korektnosti ocenjevalne komisije. Ker si želijo odkritega in poštenega mnenja. In mnenje komisije vsakič znova opisuje njihove izdelke v superlativih. Negujejo tisto, kar je dobro,  ohranjajo preizkušeno, pristno, ne hlepijo po »plehkih« novotarijah. Ostajajo zvesti sami sebi. Njihovi ocenjeni izdelki so bili iz skupin »maslo« brez in z dodatki ter »poltrdi« in »trdi siri«. Pri izdelkih brez dodatkov je bilo nedvoumno zaznati harmonično in značilno noto osnovnega izdelka, medtem ko je bilo tudi pri izdelkih z dodatki jasno, da so dodatki dodani le zaradi oplemenitenja okusa osnovnega izdelka in ne zaradi kakršnegakoli prekrivanja napak osnovnega izdelka. Okusi njihovih izdelkov so harmonični z dodatki, pri čemer se še vedno jasno občutijo vse značilnosti osnovnega izdelka. Skupini »poltrdih« in »trdih« sirov sta šolska primera tehnološko dovršenih izdelkov, kar se prepričljivo odraža v njihovih senzoričnih lastnostih. Prepričani smo, da bo proizvajalec Kärntnermilch še naprej uspel zadržati predstavljeno kakovost izdelkov, tako po tehnološki kot senzorični plati.",
                    },
                },
            },
        ],
    },
    "medene-pijace": {
        foreword:
            "o 5. ocenjevanju medenih pijač v okviru 62. mednarodnega kmetijsko-živilskega sejma Agra v Gornji Radgoni. Ocenjevanje je potekalo 10. julija 2024 na Pomurskem sejmu v Gornji Radgoni.",
        president: [
            {
                name: "Leonida Gregorič",
                position: "Kmetijsko gozdarski zavod Maribor",
            },
        ],
        members: [
            {
                name: "Tadeja Vodovnik Plevnik",
                position: "Kmetijsko gozdarski zavod Maribor",
            },
            {
                name: "Simona Hauptman",
                position: "Kmetijsko gozdarski zavod Maribor",
            },
            {
                name: "Franc Tratnjek",
                position: "Terenski svetovalec ČZS",
            },
            {
                name: "Gregor Kotolenko",
                position: "Puklavec Family Wines d.o.o.",
            },
        ],
        statistics: [
            {
                description:
                    "Na ocenjevanju je sodelovalo 13 čebelarjev oz. podjetij z 16 vzorci medenih pijač:",
                collaborators: [
                    {
                        flag: "SI",
                        country: "Slovenija",
                        numberOfCompanies: 12,
                        numberOfProducts: 14,
                    },
                    {
                        flag: "SK",
                        country: "Slovaška",
                        numberOfCompanies: 1,
                        numberOfProducts: 2,
                    },
                ],
                total: {
                    numberOfProducts: 16,
                    numberOfAwards: 14,
                    notAwarded: 2,
                    eliminated: 0,
                },
                awards: {
                    headers: [
                        "Vrsta",
                        "Št. vzorcev",
                        "Velika zlata med.",
                        "Zlata med.",
                        "Srebrna med.",
                        "Bronasta med.",
                    ],
                    rows: [
                        ["Medice", 2, 0, 1, 0, 1],
                        ["Aromatizirane medice", 1, 0, 0, 1, 0],
                        ["Peneče medice", 4, 2, 2, 0, 0],
                        ["Medeni likerji", 5, 0, 3, 2, 0],
                        ["Medeni likerji z dodatki", 1, 0, 1, 0, 0],
                        ["Medeno žganje", 1, 0, 0, 1, 0],
                        ["Skupaj", 14, 2, 7, 4, 1],
                    ],
                },
            },
        ],
    },
    med: {
        foreword:
            "o 23. ocenjevanju medu z mednarodno udeležbo v okviru 62. mednarodnega kmetijsko-živilskega sejma Agra v Gornji Radgoni. Ocenjevanje je potekalo 7. avgusta 2024 na Pomurskem sejmu v Gornji Radgoni.",
        president: [
            {
                name: "mag. Amalija Božnar",
                position: "Božnar čebelarstvo d.o.o.",
            },
        ],
        members: [
            {
                name: "prof. dr. Jasna Bertoncelj",
                position: "Biotehniška fakulteta Ljubljana",
            },
            {
                name: "Prof. dr. Dragan Bubalo",
                position:
                    "Agronomski fakultet Zagreb, Zavod za ribarstvo, pčelarstvo i zoologiju",
            },
            {
                name: "Bojan Pavlin",
                position: "Čebelarstvo Pavlin",
            },
            {
                name: "Primož Božnar",
                position: "Božnar čebelarstvo d.o.o.",
            },
            {
                name: "Jakob Madjar",
                position: "Terenski svetovalec ČZS",
            },
        ],
        afterword:
            "Ocenjevalna komisija je vzorce medu ocenjevala po veljavnem pravilniku Čebelarske zveze Slovenije. Na vseh vzorcih so bile v skladu s pravilnikom predhodno opravljene meritve vsebnosti vode in elektrolitske prevodnosti.",
        statistics: [
            {
                description:
                    "Na ocenjevanju je sodelovalo 88 čebelarjev oz. podjetij s 142 vzorci medu:",
                collaborators: [
                    {
                        flag: "SI",
                        country: "Slovenija",
                        numberOfCompanies: 78,
                        numberOfProducts: 126,
                    },
                    {
                        flag: "HR",
                        country: "Hrvaška",
                        numberOfCompanies: 7,
                        numberOfProducts: 8,
                    },
                    {
                        flag: "SK",
                        country: "Slovaška",
                        numberOfCompanies: 3,
                        numberOfProducts: 8,
                    },
                ],
                total: {
                    numberOfProducts: 142,
                    numberOfAwards: 129,
                    notAwarded: 12,
                    eliminated: 1,
                },
                awards: {
                    headers: [
                        "Vrsta",
                        "Št. vzorcev",
                        "Zlata med.",
                        "Srebrna med.",
                        "Bronasta med.",
                    ],
                    rows: [
                        ["Akacija", 26, 6, 8, 10],
                        ["Ajda", 2, 0, 0, 1],
                        ["Cvetlični", 26, 7, 16, 1],
                        ["Gozdni", 36, 14, 14, 6],
                        ["Hoja", 4, 1, 2, 1],
                        ["Kostanj", 36, 2, 21, 7],
                        ["Lipa", 10, 1, 6, 3],
                        ["Smreka", 2, 1, 1, 0],
                        ["Skupaj", 142, 32, 68, 29],
                    ],
                },
            },
        ],
    },
    "mesni-izdelki": {
        foreword:
            "o 45. mednarodnem ocenjevanju kakovosti mesa in mesnih izdelkov na 62. mednarodnem kmetijsko živilskem sejmu Agra v Gornji Radgoni. Ocenjevanje mesnih izdelkov (kategorija A, B, C) in meso in mesni pripravki (kategorija D) je potekalo od 22. do 24. maja 2024 in 12. in 13. junija 2024",
        president: [
            {
                name: "Prof. dr. Božidar Žlender",
                position: "Kategorija A, B, C. Biotehniška fakulteta Ljubljana",
            },
            {
                name: "Doc. dr. Mateja Lušnic Polak",
                position: "Kategorija D. Biotehniška fakulteta Ljubljana",
            },
        ],
        members: [
            {
                name: "Katja Japelj, univ. dipl. inž. živ. tehnol.",
                position: "Biotehniška fakulteta Ljubljana",
            },
            {
                name: "izr. prof. dr. Tomaž Požrl",
                position: "Biotehniška fakulteta Ljubljana",
            },
            {
                name: "mag. Marlena Skvarča",
                position: "Biotehniška fakulteta Ljubljana",
            },
            {
                name: "Mojca Kuhar, mag. inž. živ.",
                position: "Biotehniška fakulteta Ljubljana",
            },
            {
                name: "Iva Zahija, mag. inž. živ.",
                position: "Biotehniška fakulteta Ljubljana",
            },
            {
                name: "doc. dr. Stanka Vadnjal",
                position: "Veterinarska fakulteta Ljubljana",
            },
            {
                name: "Tatjana Šubic, univ. dipl. ing.",
                position:
                    "Višja strokovna šola za gostinstvo, velnes in turizem Bled",
            },
            {
                name: "Barbara Bratuša",
                position: "Bureau Veritas d.o.o.",
            },
            {
                name: "Irena Kos",
                position: "Kmetijsko-gozdarski zavod Celje",
            },
            {
                name: "Mira Domjan, univ. dipl. inž. živ. teh.",
                position: "Osem d.o.o.",
            },
            {
                name: "Andrej Bitežnik",
                position: "Perutnina Ptuj d.o.o.",
            },
            {
                name: "Aljoša Pegan",
                position: "Kras d.o.o.",
            },
            {
                name: "Igor Kustec, univ. dipl. inž. teh.",
                position: "Panvita MIR d.o.o.",
            },
            {
                name: "Uroš Morela",
                position: "Meso Kamnik d.d.",
            },
            {
                name: "Tatjana Berčič",
                position: "Pivka perutninarstvo d.d.",
            },
            {
                name: "Saša Stopar",
                position: "Celjske mesnine d.o.o.",
            },
            {
                name: "Tomo Lečnik",
                position: "Mesarija gostinstvo Lečnik, Tomislav Lečnik s.p.",
            },
        ],
        technicalLeader: {
            name: "Mojca Malenšek",
            position: "Biotehniška fakulteta Ljubljana",
        },
        statistics: [
            {
                description:
                    "Na ocenjevanju je sodelovalo 44 podjetij s 505 izdelki",
                collaborators: [
                    {
                        flag: "SI",
                        country: "Slovenija",
                        numberOfCompanies: 42,
                        numberOfProducts: 481,
                    },
                    {
                        flag: "HR",
                        country: "Hrvaška",
                        numberOfCompanies: 2,
                        numberOfProducts: 24,
                    },
                ],
                total: {
                    numberOfProducts: 505,
                    numberOfAwards: 491,
                    notAwarded: 9,
                    eliminated: 4,
                },
                awards: {
                    titles: [
                        "Šampion",
                        "Velika zlata medalja",
                        "Zlata medalja",
                        "Zlata medalja za tržno ponudbo",
                        "Srebrna medalja",
                        "Srebrna medalja za tržno ponudbo",
                        "Bronasta medalja",
                        "Bronasta medalja za tržno ponudbo",
                    ],
                    numbers: [23, 1, 94, 53, 211, 64, 34, 11],
                },
                extras: {
                    productsByCategory: [
                        {
                            product: "A - Standardni izdelki",
                            company: 336,
                        },
                        {
                            product: "B - Novi izdelki",
                            company: 21,
                        },
                        {
                            product: "C - Varovalne mesnine",
                            company: 13,
                        },
                        {
                            product: "D - meso",
                            company: 135,
                        },
                    ],
                    awardsByCategory: [
                        {
                            title: "A - Standardni izdelki",
                            titles: [
                                "Šampion",
                                "Velika zlata medalja",
                                "Zlata medalja",
                                "Srebrna medalja",
                                "Bronasta medalja",
                            ],
                            numbers: [16, 1, 87, 187, 32],
                        },
                        {
                            title: "B - Novi izdelki",
                            titles: [
                                "Šampion",
                                "Velika zlata medalja",
                                "Zlata medalja",
                                "Srebrna medalja",
                                "Bronasta medalja",
                            ],
                            numbers: [0, 0, 4, 16, 1],
                        },
                        {
                            title: "C - Varovalne mesnine",
                            titles: [
                                "Šampion",
                                "Velika zlata medalja",
                                "Zlata medalja",
                                "Srebrna medalja",
                                "Bronasta medalja",
                            ],
                            numbers: [1, 0, 3, 8, 1],
                        },
                        {
                            title: "D - meso",
                            titles: [
                                "Šampion",
                                "Velika zlata medalja",
                                "Zlata medalja za tržno ponudbo",
                                "Srebrna medalja za tržno ponudbo",
                                "Bronasta medalja za tržno ponudbo",
                            ],
                            numbers: [6, 0, 53, 64, 11],
                        },
                    ],
                    specialAwardsABC: [
                        {
                            product: "Lokavski pršut kosi s krasa",
                            company: "Celjske mesnine d.o.o.",
                        },
                        {
                            product: "Domača gorenjska zaseka 275 g",
                            company: "Čadež d.o.o.",
                        },
                        {
                            product: "Bakala (namaz iz sušene trske s tartufi)",
                            company:
                                "Formio, predelava in trženje rib, Denis Cunja s.p.",
                        },
                        {
                            product:
                                "Puranja pašteta groba (domač kmečki namaz)",
                            company: "Hermine Wech - Koroška perutnina d.o.o.",
                        },
                        {
                            product: "Sevniška salama - Krškopoljka",
                            company: "Kmečka zadruga Sevnica z.o.o.",
                        },
                        {
                            product: "Domača salama",
                            company: "Kmetijska zadruga Tolmin, z.o.o.",
                        },
                        {
                            product: "Mortadela Elita 10/1",
                            company: "Kras d.o.o.",
                        },
                        {
                            product: "Primorska domača salama",
                            company: "Kras d.o.o.",
                        },
                        {
                            product: "Mortadela Prestige 10/1",
                            company: "Kras d.o.o.",
                        },
                        {
                            product: "Kraški salamin",
                            company: "Kras d.o.o.",
                        },
                        {
                            product: "Ričet",
                            company:
                                "Mesarija gostinstvo Lečnik, Tomislav Lečnik s.p.",
                        },
                        {
                            product: "Haloška slaninska salama",
                            company: "Mesarija Kokol d.o.o.",
                        },
                        {
                            product: "Zaseka gorenjska",
                            company: "Mesarstvo Oblak d.o.o.",
                        },
                        {
                            product: "Zaseka",
                            company: "Meso izdelki Žerak, Marko Žerak s.p.",
                        },
                        {
                            product: "Zlata mortadela Anton",
                            company: "Meso Kamnik d.d.",
                        },
                        {
                            product: "Zlata mortadela z olivami Anton",
                            company: "Meso Kamnik d.d.",
                        },
                        {
                            product:
                                "Poli Kids classic narezek (zmanjšana vsebnost soli)",
                            company: "Perutnina Ptuj d.o.o.",
                        },
                    ],
                    specialAwardsD: [
                        {
                            product: "Kuhani vampi 500 g",
                            company: "Celjske mesnine d.o.o.",
                        },
                        {
                            product: "Marinirana piščančja krilca",
                            company: "Hermine Wech - Koroška perutnina d.o.o.",
                        },
                        {
                            product: "Prsa file	",
                            company: "Kmetija Grgar",
                        },
                        {
                            product: "Suho zorjeni goveji rostbif",
                            company: "Kmetijska zadruga Rače z.o.o.",
                        },
                        {
                            product: "Leskovačke pleskavice Anton",
                            company: "Meso Kamnik d.d.",
                        },
                        {
                            product: "Leskovački čevapčiči AVE",
                            company: "Panvita MIR d.d.",
                        },
                    ],
                },
            },
        ],
    },
    sokovi: {
        foreword:
            "o 28. mednarodnem ocenjevanju sokov, brezalkoholnih pijač in embaliranih vod v okviru 62. mednarodnega kmetijsko-živilskega sejma Agra v Gornji Radgoni. Ocenjevanje je potekalo v prostorih Pomurskega sejma v Gornji Radgoni, dne 10. in 11. junija 2024.",
        president: [
            {
                name: "izr. prof. dr. Tomaž POŽRL",
                position: "Biotehniška fakulteta, Ljubljana",
            },
        ],
        members: [
            {
                name: "prof. dr. Rajko VIDRIH",
                position: "Biotehniška fakulteta, Ljubljana",
            },
            {
                name: "univ. dipl. ing. Andrej ŽIVKOVIĆ",
                position: "Biotehniška fakulteta, Ljubljana",
            },
            {
                name: "Zdenka ZUPANČIČ",
                position: "Biotehniška fakulteta, Ljubljana",
            },
            {
                name: "dr. Majda Barbara POVŠE",
                position: "Murska Sobota",
            },
        ],
        statistics: [
            {
                description:
                    "Na ocenjevanju je sodelovalo 32 podjetij s 97 izdelki:",
                collaborators: [
                    {
                        flag: "SI",
                        country: "Slovenija",
                        numberOfCompanies: 29,
                        numberOfProducts: 93,
                    },
                    {
                        flag: "HR",
                        country: "Hrvaška",
                        numberOfCompanies: 2,
                        numberOfProducts: 2,
                    },
                    {
                        flag: "HU",
                        country: "Madžarska",
                        numberOfCompanies: 1,
                        numberOfProducts: 2,
                    },
                ],
                total: {
                    numberOfProducts: 97,
                    numberOfAwards: 96,
                    notAwarded: 1,
                    eliminated: 0,
                },
                awards: {
                    headers: [
                        "Vrsta",
                        "Št.vzorcev",
                        "Šampion",
                        "Velika zlata med.",
                        "Zlata med.",
                        "Srebrna med.",
                        "Bronasta med.",
                    ],
                    rows: [
                        ["Sadni sokovi", 19, 0, 5, 6, 5, 3],
                        ["Sadni nektarji", 4, 0, 1, 3, 0, 0],
                        ["Sirupi", 18, 0, 3, 6, 7, 2],
                        ["Pijače", 50, 2, 8, 16, 21, 3],
                        ["Embalirane vode", 5, 0, 0, 3, 2, 0],
                        ["Skupaj", 96, 2, 17, 34, 35, 8],
                    ],
                },
            },
        ],
    },
    vino: {
        foreword:
            "o 50. odprtem državnem ocenjevanju vin - Vino Slovenija Gornja Radgona in 14. ocenjevanju BIO vin v okviru sejma AGRA - 62. mednarodnega kmetijsko-živilskega sejma v Gornji Radgoni. Ocenjevanje je potekalo od 8. do 12. julija 2024 v prostorih Pomurskega sejma v Gornji Radgoni.",
        president: [
            {
                name: "Boštjan Zidar",
                position: "Glavni enolog",
            },
        ],
        committeeDescription:
            "Ocenjevanje Vino Slovenija je potekalo v štirih komisijah, ki so jim predsedovali Boštjan Zidar, Danilo Steyer, mag. Janez Valdhuber in Iztok Klenar. Ob njih so ocenjevali enologi, ki se enako kot predsedniki komisij ponašajo z dolgoletnimi in mednarodnimi izkušnjami:",
        members: [
            {
                name: "Danilo Steyer",
                position: "",
            },
            {
                name: "mag. Janez Valdhuber",
                position: "",
            },
            {
                name: "Nika Gregorič",
                position: "",
            },
            {
                name: "Marijan Čižmešija",
                position: "",
            },
            {
                name: "Bruno Gaberšek",
                position: "",
            },
            {
                name: "Alojz Cedula",
                position: "",
            },
            {
                name: "Majda Brdnik",
                position: "",
            },
            {
                name: "Darja Zemljič",
                position: "",
            },
            {
                name: "Mojmir Wondra",
                position: "",
            },
            {
                name: "Ivanka Badovinac",
                position: "",
            },
            {
                name: "Jure Štalcar",
                position: "",
            },
            {
                name: "Klavdija Topolovec Špur",
                position: "",
            },
            {
                name: "Simona Hauptman",
                position: "",
            },
            {
                name: "Darinko Ribolica",
                position: "",
            },
            {
                name: "Luka Radiček",
                position: "",
            },
            {
                name: "Anna Schachner",
                position: "",
            },
            {
                name: "Dušan Brejc",
                position: "",
            },
            {
                name: "Ingrid Mahnič",
                position: "",
            },
            {
                name: "Mitja Herga",
                position: "",
            },
            {
                name: "Jure Grubar",
                position: "",
            },
            {
                name: "Tadeja Vodovnik Plevnik",
                position: "",
            },
            {
                name: "Leonida Gregorič",
                position: "tajnik ocenjevanja",
            },
        ],
        statistics: [
            {
                categories: [
                    {
                        description:
                            "Na ocenjevanju je sodelovalo 148 vinarjev s 651 vzorcev:",

                        collaborators: [
                            {
                                flag: "SI",
                                country: "Slovenija",
                                numberOfCompanies: 118,
                                numberOfProducts: 537,
                            },
                            {
                                flag: "HR",
                                country: "Hrvaška",
                                numberOfCompanies: 10,
                                numberOfProducts: 37,
                            },
                            {
                                flag: "AT",
                                country: "Avstrija",
                                numberOfCompanies: 8,
                                numberOfProducts: 41,
                            },
                            {
                                flag: "BG",
                                country: "Bolgarija",
                                numberOfCompanies: 3,
                                numberOfProducts: 18,
                            },
                        ],
                        total: {
                            numberOfProducts: 651,
                            numberOfAwards: 603,
                            notAwarded: 30,
                            eliminated: 0,
                        },
                        awards: {
                            titles: [
                                "Šampion",
                                "Velika zlata medalja",
                                "Zlata medalja",
                                "Srebrna medalja",
                                "Brez odličja",
                            ],
                            numbers: [14, 23, 232, 334, 30],                            
                        },
                    },
                    {
                        description:
                            "Na ocenjevanju BIO vin je sodelovalo 9 pridelovalcev z 18 vzorci vina:",
                        collaborators: [
                            {
                                flag: "SI",
                                country: "Slovenija",
                                numberOfCompanies: 7,
                                numberOfProducts: 10,
                            },
                            {
                                flag: "HR",
                                country: "Hrvaška",
                                numberOfCompanies: 1,
                                numberOfProducts: 4,
                            },
                            {
                                flag: "AT",
                                country: "Avstrija",
                                numberOfCompanies: 1,
                                numberOfProducts: 4,
                            },
                        ],
                        total: {
                            numberOfProducts: 18,
                            numberOfAwards: 18,
                            notAwarded: 0,
                            eliminated: 0,
                        },
                        awards: {
                            titles: [
                                "Šampion",
                                "Velika zlata medalja",
                                "Zlata medalja",
                                "Srebrna medalja",
                                "Brez odličja",
                            ],
                            numbers: [0, 0, 5, 13, 0],
                        },
                    },
                ],
                samplesByCategory: [
                    {
                        product: "Bela mirna vina - suha",
                        company: 160,
                    },
                    {
                        product: "Rose vina - suha",
                        company: 19,
                    },
                    {
                        product: "Rdečkasta vina - Cviček PTP",
                        company: 27,
                    },
                    {
                        product: "Rose vina - polsuha",
                        company: 7,
                    },
                    {
                        product: "Rose vina - polsladka",
                        company: 3,
                    },
                    {
                        product: "Bela mirna vina - suha - Belokranjec PTP",
                        company: 2,
                    },
                    {
                        product: "Bela mirna vina - polsuha",
                        company: 15,
                    },
                    {
                        product: "Rdeča vina - suha",
                        company: 117,
                    },
                    {
                        product: "Rdeča vina - Teran PTP",
                        company: 41,
                    },
                    {
                        product: "Rdeča vina - Metliška črnina PTP",
                        company: 2,
                    },
                    {
                        product: "Rdeča vina - polsuha",
                        company: 1,
                    },
                    {
                        product: "Rdeča vina- polsladka",
                        company: 1,
                    },
                    {
                        product: "Rdeča vina- sladka",
                        company: 5,
                    },
                    {
                        product: "Mirna vina aromatičnih sort - suha",
                        company: 63,
                    },
                    {
                        product: "Mirna vina aromatičnih sort - polsuha",
                        company: 13,
                    },
                    {
                        product: "Mirna vina aromatičnih sort - polsladka",
                        company: 26,
                    },
                    {
                        product: "Mirna vina aromatičnih sort - sladka",
                        company: 25,
                    },
                    {
                        product: "Bela mirna vina - polsladka",
                        company: 7,
                    },
                    {
                        product: "Bela mirna vina - sladka",
                        company: 12,
                    },
                    {
                        product: "Biser vina- rose- polsuha",
                        company: 1,
                    },
                    {
                        product: "Peneča vina ",
                        company: 74,
                    },
                    {
                        product: "Nearomatične sorte - alk. < 18%, slad. 6,1 do 40g/L",
                        company: 2,
                    },
                    {
                        product: "Nearomatične sorte - alk. > 18%, slad. > 80g/L",
                        company: 2,
                    },
                    {
                        product: "Nearomatične sorte - alk. <= 18%, slad. > 80 g/L",
                        company: 6,
                    },
                    {
                        product: "Aromatične sorte",
                        company: 3,
                    },
                ],
                samplesByCategoryBio: [
                    {
                        product: "Bela mirna vina - suha",
                        company: 6,
                    },
                    {
                        product: "Rdeča vina - suha",
                        company: 5,
                    },
                    {
                        product: "Rdeča vina - polsuha",
                        company: 1,
                    },
                    {
                        product: "Mirna vina aromatičnih sort - suha",
                        company: 3,
                    },
                    {
                        product: "Mirna vina aromatičnih sort - polsladka",
                        company: 1,
                    },
                    {
                        product: "Peneča vina ",
                        company: 2,
                    },
                ],

            },
        ],
    },
    "slo-oskar-za-embalazo": {
        foreword:
            "",
        president: [
            {
                name: "dr. David RAVNJAK",
                position: "Direktor Inštituta za celulozo in papir Ljubljana",
            },
        ],
        members: [
            {
                name: "prof. dr. Gregor RADONJIČ",
                position: "Ekonomsko-poslovna fakulteta, Univerza v Mariboru, Predstojnik Katedre za tehnologijo in podjetniško varstvo okolja",
            },
            {
                name: "prof. Domen FRAS",
                position: "Univerza v Ljubljani, Akademija za likovno umetnost in oblikovanje, Oddelek za oblikovanje vizualnih komunikacij",
            },
            {
                name: "izr. prof. Rok KUHAR",
                position: "Univerza v Ljubljani, Akademija za likovno umetnost in oblikovanje Oddelek za industrijsko in unikatno oblikovanje",
            },
            {
                name: "prof. dr. Diana Gregor SVETEC",
                position: "Univerza v Ljubljani, Naravoslovnotehniška fakulteta Katedra za informacijsko in grafično tehnologijo",
            },
        ],
        
    },
    mehanizacija: {
        noInfo: "Za to kategorijo še ni podatkov.",
    },
};
