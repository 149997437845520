import React, { useState } from 'react'
import SubPageImageSection from "../SubPageImageSection";
import BackToHome from "../BackToHome";
import { backGroundTitle } from "../Data/dataCategories";

const MehanizacijaRecord = () => {

    let url = window.location.pathname;
    let category = url.split("/")[2];
    let backLink = url.replace("/record", "");
    /*const [data, setData] = useState<any>(
        records[category as keyof typeof records]
    );*/

  return (
    <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="record-section">
                <BackToHome
                    title={"Nazaj na " + backGroundTitle(backLink)}
                    link={backLink}
                />

                {<h1 className="no-info">Za to kategorijo še ni zapisnika.</h1>}
                
            </div>
        </>
  )
}

export default MehanizacijaRecord
