import React from "react";
import { president, members } from "./data";
import BackToHome from "../BackToHome";
import SubPageImageSection from "../SubPageImageSection";
import { backGroundTitle } from "../Data/dataCategories";
import Member from "./Member";

function ProgramCouncil() {
    let url = window.location.pathname;
    return (
        <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="council-section">
                <BackToHome title="Nazaj na Katalog nagrad" />
                <h2>Predsednica</h2>
                <Member name={president.name} position={president.position} />
                <div className="divider"></div>
                <h2>Člani</h2>
                <div className="members">
                {members.map((member, index) => (
                    <Member
                        key={index}
                        name={member.name}
                        position={member.position}
                    />
                ))}
                </div>
            </div>
        </>
    );
}

export default ProgramCouncil;
