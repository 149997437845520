import React from "react";

interface BackToHomeProps {
    title: string;
    link?: string;
}

const BackToHome: React.FC<BackToHomeProps> = ({ title, link }) => {
    if (link === undefined) {
        link = "/";
    }
    return (
        <div className="back-home">
            <a href={link} >
                <svg
                    width="10"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                >
                    <path
                        fill="#F49F21"
                        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                    />
                </svg>{" "}
                {title}
            </a>
        </div>
    );
};

export default BackToHome;
