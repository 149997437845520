import React from "react";
interface ArticleProps {
    company: string;
    title: string;
    author: string;
    content: Array<string>;
}

const Article: React.FC<ArticleProps> = ({
    company,
    title,
    author,
    content,
}) => {
    return (
        <div className="article">
            <p className="company">{company}</p>
            <p className="title">{title}</p>
            <p className="author">{author}</p>
            <div className="content">
                {content.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
        </div>
    );
};

export default Article;
