import React from "react";

interface CountryProps {
    flag: string;
    country: string;
}

function Country({ flag, country }: CountryProps) {
    return (
        <div className="country">
            <img
                alt={flag}
                width="24px"
                height="16px"
                src={
                    "https://purecatamphetamine.github.io/country-flag-icons/3x2/" +
                    flag +
                    ".svg"
                }
            />
            <span>{country}</span>
        </div>
    );
}

export default Country;
