import React, { useState } from "react";
import { records } from "./data";
import SubPageImageSection from "../SubPageImageSection";
import { backGroundTitle } from "../Data/dataCategories";
import BackToHome from "../BackToHome";
import Member from "../ProgramCouncil/Member";
import DynamicTable from "./DynamicTable";
import AwardsTable from "./AwardsTable";
import Table from "./Table";
import TwoDTable from "./TwoDTable";
import Stats from "./Stats";
import VinoAwardsTable from "./VinoAwardsTable";

function VinoRecord() {
    let url = window.location.pathname;
    let category = url.split("/")[2];
    let backLink = url.replace("/record", "");
    const [data, setData] = useState<any>(
        records[category as keyof typeof records]
    );

    return (
        <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="record-section">
                <BackToHome
                    title={"Nazaj na " + backGroundTitle(backLink)}
                    link={backLink}
                />

                {data.noInfo && <h1 className="no-info">{data.noInfo}</h1>}
                {!data.noInfo && (
                    <>
                        <p className="foreword">{data.foreword}</p>
                        <h1>Ocenjevalna komisija</h1>
                        <h2>Predsednik ocenjevanja</h2>
                        <p className="foreword">{data.committeeDescription}</p>
                        <div className="members">
                            {data.president.map(
                                (
                                    president: {
                                        name: string;
                                        position: string;
                                    },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={president.name}
                                        position={president.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>
                        <h2>Člani</h2>
                        <div className="members">
                            {data.members.map(
                                (
                                    member: { name: string; position: string },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={member.name}
                                        position={member.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>
                        {data.technicalLeader && (
                            <>
                                <h2>Tehnični vodja</h2>
                                <Member
                                    name={data.technicalLeader.name}
                                    position={data.technicalLeader.position}
                                />
                            </>
                        )}

                        <h1>Statistični podatki ocenjevanja</h1>
                        {data.statistics.map(
                            (
                                statistics: any,
                                index: React.Key | null | undefined
                            ) => {
                                return (
                                    <div key={index}>
                                        <p className="foreword">
                                            Število vzorcev po posameznih
                                            kategorijah na ocenjevanju 2024:
                                        </p>
                                        <Table
                                            data={statistics.samplesByCategory}
                                            headers={[
                                                "Naziv skupine",
                                                "Število izdelkov",
                                            ]}
                                        />
                                        <p className="foreword">
                                            Število vzorcev po posameznih
                                            kategorijah na ocenjevanju BIO vin:
                                        </p>
                                        <Table
                                            data={
                                                statistics.samplesByCategoryBio
                                            }
                                            headers={[
                                                "Naziv skupine",
                                                "Število izdelkov",
                                            ]}
                                        />

                                        {statistics.categories.map(
                                            (
                                                category: any,
                                                index:
                                                    | React.Key
                                                    | null
                                                    | undefined
                                            ) => {
                                                return (
                                                    <div key={index}>
                                                        <p className="foreword">
                                                            {
                                                                category.description
                                                            }
                                                        </p>
                                                        <div className="collaborators">
                                                            <DynamicTable
                                                                data={
                                                                    category.collaborators
                                                                }
                                                                headers={[
                                                                    "Država",
                                                                    "Število podjetij",
                                                                    "Število izdelkov",
                                                                ]}
                                                            />
                                                            <Stats
                                                                data={category}
                                                            />
                                                        </div>
                                                        <p className="foreword">
                                                            Podeljena odličja:
                                                        </p>
                                                        <VinoAwardsTable
                                                            data={
                                                                category.awards
                                                            }
                                                            headers={[
                                                                "Nagrada",
                                                                "Število nagrajenih",
                                                                "Delež",
                                                            ]}
                                                            total={
                                                                category.total
                                                                    .numberOfProducts
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            }
                        )}
                        <h2>Posebna priznanja</h2>
                        <h3>PENEČA VINA (klasična metoda)</h3>
                        <p className="foreword">
                            Zlata radgonska penina "Millesime", 2015, Radgonske
                            gorice Gornja Radgona
                        </p>
                        <h3>PENEČA VINA (Charmat metoda)</h3>
                        <p className="foreword">
                            Penina REN, 2018, Vina Benčina
                        </p>
                        <h3>CVIČEK PTP</h3>
                        <p className="foreword">
                            Cviček PTP, 2023, Hiša vina Grabnar
                        </p>
                        <h3>BELA VINA NEAROMATIČNIH SORT - SUHA</h3>
                        <p className="foreword">
                            Chardonnay, 2023, Kmetija Furlan
                        </p>
                        <h3>BELA VINA AROMATIČNIH SORT - SUHA</h3>
                        <p className="foreword">
                            Muskateller, 2023, Weingut u. Steirische Kelleri
                            Johann Schneeberger GmbH
                        </p>
                        <h3>
                            BELA MIRNA VINA POLSUHA in MIRNA VINA AROMATIČNIH
                            SORT - POLSUHA
                        </h3>
                        <p className="foreword">
                            Rumeni muškat, 2023, Vina Povh
                        </p>
                        <p className="foreword">
                            Rumeni muškat, 2023, Vina Benčina
                        </p>
                        <h3>MIRNA VINA AROMATIČNIH SORT - POLSLADKA</h3>
                        <p className="foreword">
                            Rumeni muškat, 2020, Vinska klet Prus, Jožef Prus
                        </p>
                        <h3>MIRNA VINA AROMATIČNIH SORT - SLADKA</h3>
                        <p className="foreword">
                            Rumeni muškat, 2018, Vinska klet Prus, Jožef Prus
                        </p>
                        <p className="foreword">
                            Sauvignon, 2001, Vinska klet Prus, Jožef Prus
                        </p>
                        <h3>RDEČA SUHA VINA MLAJŠA OD TREH LET</h3>
                        <p className="foreword">
                            Cabernet Sauvignon, 2021, Vina Zgonik, Damir Zgonik
                        </p>
                        <h3>RDEČA SUHA VINA STAREJŠA OD TREH LET</h3>
                        <p className="foreword">Teste matte, 2020, San Mauro</p>
                        <h3>RDEČA VINA TERAN PTP</h3>
                        <p className="foreword">
                            Izbrani teran PTP La Marie 2, 2021, Vinakras z.o.o.
                            Sežana
                        </p>
                        <h3>NARAVNA SLADKA DESERTNA VINA</h3>
                        <p className="foreword">
                            Rumeni muškat, 2013, Vinska klet Prus, Jožef Prus
                        </p>

                        <h2>Posebne nagrade</h2>
                        <h3>NAGRADA STANKA ČURINA</h3>
                        <p className="foreword">
                            Sauvignon 2001 - 97,67 točk, Vinska klet Prus, Jožef
                            Prus
                        </p>
                        <h3>PRVAK AVSTRIJE 2024</h3>
                        <p className="foreword">
                            Muskateller 2023 – 90,67 točk, Weingut u. Steirische
                            Kellerei Johan Schneeberger
                        </p>
                        <h3>PRVAK HRVAŠKE 2024</h3>
                        <p className="foreword">
                            Traminac mirisavi izbor bobica, 2018 - 94,67 točk,
                            Vino Lovrec, OPG Krešimir Lovrec
                        </p>

                        <h2>PRVAKI VINSKO TURISTIČNIH CEST 2024</h2>
                        <h3>VIPAVSKA VINSKA TURISTIČNA CESTA</h3>
                        <p className="foreword">
                            CABERNET SAUVIGNON, Vina Zgonik, Damir Zgonik
                        </p>
                        <h3>KRAšKA VINSKA TURISTIČNA CESTA</h3>
                        <p className="foreword">
                            VITOVSKA Prestige, Vinakras z.o.o. Sežana
                        </p>

                        <h2>Vinar leta</h2>
                        <h3>Vinar leta bo razglašen na svečani razglasitvi rezultatov in podelitvi medalj na sejmu Agra 2024.</h3>
                    </>
                )}
            </div>
        </>
    );
}

export default VinoRecord;
