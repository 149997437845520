import React from 'react'
import MainSection from '../ImageSection'
import CategoryOverview from './CategoryOverview'

const Catalog = () => {
  return (
    <div>
        <MainSection title={"KATALOG NAGRAD"} year={"2024"}/>
        <CategoryOverview />
    </div>
  )
}

export default Catalog
