export let commentary = {
    "mlecni-izdelki": [
        {
            company: "Gornja Radgona, 31.05.2024",
            title: "Komentar predsednice komisije",
            author: "Predsednica ocenjevalne komisije izr. prof. dr. Andreja Čanžek Majhenič",
            content: [
                "Člani komisije smo veseli, da vsem tegobam navkljub (pandemija kovida, naravne ujme,…) tako industrija kot mali proizvajalci ostajajo zvesti Pomurskemu sejmu in so se tudi letos s svojimi vzorci mlečnih izdelkov udeležili mednarodnega strokovnega ocenjevanja mleka in mlečnih izdelkov. Slednje, ki vsako leto poteka v okviru mednarodnega kmetijsko-živilskega sejma Agra v Gornji Radgoni, je edino v Sloveniji, ki sledi kriterijem mednarodnega standarda za senzorično analizo mleka in mlečnih izdelkov ISO 22935-2/ IDF 99-2:2009 in je skladno s Pravilnikom o ocenjevanju senzorične kakovosti mleka in mlečnih izdelkov. Zato velja organizatorju ocenjevanja, Pomurskemu sejmu, posebno priznanje, da ohranja to tradicijo ocenjevanja s strokovno mednarodno komisijo. Končno potrditev sprejemljivosti mleka in mlečnih izdelkov seveda poda potrošnik, a člani strokovne komisije cenimo trud, voljo ter zavzetost proizvajalcev, da želijo potrditev tudi tehnološke kakovosti izdelkov, ki se odraža v njihovi senzorični kakovosti. V letošnjem letu smo od 174 prijavljenih ocenili 173 vzorcev, kar je sicer 9 izdelkov manj kot lani, a vsekakor na nivoju dolgoletnega povprečja.",
                "Veseli nas redna prisotnost istih izdelkov, ki že vrsto let dokazujejo konstantno visoko kakovost, kar potrjuje željo proizvajalcev po vzdrževanju stalne kakovosti ter hkrati njeno potrditev na mednarodnem nivoju. Pa vendar se že obstoječi družini mleka in mlečnih izdelkov pridružujejo vedno novi in novi izdelki, predvsem malih proizvajalcev, ki želijo na tak način pridobiti informacije, kje kotira kakovost njihovih izdelkov oz. kje je še prostor za morebitne izboljšave. Iz podane statistike organizatorja je razvidno, da tako po številu proizvajalcev (22) kot po številu izdelkov (120) prednjači Slovenija, medtem ko so k senzoričnemu ocenjevanju pristopili 3 hrvaški proizvajalci mleka in mlečnih izdelkov s skupno 31 izdelki ter 1 avstrijski proizvajalec s 23 izdelki. Podeljenih je bilo 10 šampionov kakovosti (lani 9) naslednjim izdelkom: Kärntnermilch Blütenkäse, Bio Wiesenmilch Almkäse 50% F.i.T., Bio Wiesenmilch Rahmkäse 55% F.i.T., Kärntnermilch Kräuterbutter, Kärntnermilch Steinpilzbutter, Štrukelj s temno čokolado in malinami, Ementalec, Mleko v prahu, Mliječni puding vanilija in &#39;z bregov UHT Kozje mlijeko. Poudariti velja, da so med šampioni tako izdelki slovenskih kot hrvaških in avstrijskega proizvajalca. Podana statistika ocen nakazuje nekoliko slabšo kakovost letošnjih izdelkov, kar se kaže v manjšem deležu podeljenih zlatih medalj (letos 15,52 % / lani 21,98 %) ter velikih zlatih medalj (letos 24,14 / lani 24,73 %), posledično pa sta bila večja deleža podeljenih srebrnih medalj (letos 36,21 % / lani 32,42 %) ter bronastih medalj (letos 15,52 % / lani 11,54 %). Število nenagrajenih izdelkov je bilo letos 5, lani 8. Izločen je bil en (1) izdelek.",
                "Že četrto leto zapored je bil velik delež vzorcev iz skupine sladoledov in zamrznjenih desertov, saj so tudi letos ti izdelki predstavljali približno 1/3 vseh izdelkov. Sladoledi in zamrznjeni deserti so skupina izdelkov, ki za dosego optimalne tehnološke kakovosti zahtevajo več tehnoloških dodatkov, ki pogojujejo senzorično kakovost. Pri sladoledu v prvi vrsti pričakujemo, da ima dober, harmoničen okus. Ker pa se pri senzoričnem ocenjevanju vrednotita tudi konsistence izdelka in način topljenja, imajo tehnološki dodatki na končno oceno teh lastnosti velik vpliv. Zaradi vedno pogostejše uporabe nemlečnih sestavin, npr. rastlinskih maščob, sladil, in povečanega deleža beljakovin, imajo ti izdelki hitro lahko več izraženih napak, kot so lepljiva, puhasta, testasta konsistenca, peskasta struktura, slabe lastnosti topljenja, razslojevanje, itd. Komisija hkrati pogreša čiste, osnovne okuse, predvsem sladoledov, saj v tej skupini vedno pogosteje prevladujejo izdelki s kopico raznoraznih dodatkov, ki popolnoma zameglijo in neharmonično prekrijejo osnovni izdelek.",
                "V letošnjem letu nas je presenetil precej majhen nabor izdelkov iz skupine fermentiranega mleka, tako brez kot z dodatki. Pri peščici vzorcev iz skupine fermentiranega mleka, smo pri fermentiranem mleku brez dodatkov pogrešili izrazitejšo in harmonično mlečnokislinsko aromo, brez lepljive konsistence. Pri fermentiranem mleku z dodatki pa harmoničnost okusa dodatka z mlečno osnovo, in še vedno prepoznavno značilnost okusa osnovnega fermentiranega mleka.",
                "Naj poudarimo, da se v skupini &quot;drugi mlečni izdelki&quot; vsako leto znajdejo zanimivi in kvalitetni izdelki. To dokazuje inovativnost in možnost uporabe mleka in mlečnih izdelkov v drugih živilskih izdelkih.",
                "Izkazalo se je tudi, da je splošno poznavanje zahtev za deklariranje izdelkov nekoliko pomanjkljivo, tako pri industrijskih kot manjših proizvajalcih, ker še vedno prihaja do napačnega in/ali pomanjkljivega deklariranja izdelkov. Posebno pri sirih proizvajalci nekako radi posegajo po terminologiji iz vinarstva (staran sir, mladi sir ipd.), kar je napačno. Eden od pomembnih tehnoloških korakov v tehnološkem postopku izdelave zorenih sirov je zorenje. Optimalni čas zorenja do konzumne zrelosti je za vsako vrsto sira določen. Zatorej mora biti morebitno skrajšanje zorenja opredeljeno in razvidno iz priložene specifikacije na zahtevo, ki botruje izdelku z nežneje izraženimi senzoričnimi lastnostmi, a še vedno značilnimi za to vrsto sira. Poudariti je treba, da ni vsaka vrsta sira primerna za skrajšano zorenje. Vse pogosteje se celo dogaja, da so nekateri siri deklarirani kot mladi siri, predvsem siri malih proizvajalcev. Ker pa ta kategorija sirov ne obstaja in je izdelek tako napačno deklariran, ima komisija nehvaležno nalogo tak sir korektno senzorično ovrednotiti. Najverjetneje gre v primeru »mladih sirov« za sire, izdelane po tehnološkem postopku za poltrde sire, ki pa so dani v prodajo takoj po izdelavi, brez zorenja. Če pa sir ne zori in je naprodaj takoj po izdelavi, gre za nezoren oz. sveži sir in ne za mladi sir. Slednji izraz nič ne pove o uporabljenem tehnološkem postopku.",
                "Druga pomanjkljivost zapisa na deklaraciji, posebej na deklaracijah sirov, pa je ta, da sira ni moč uvrstiti v pravo ocenjevalno skupino. Recimo da je zapisano, da gre za poltrdi sir, kar je korektno, a gre samo za enoto sira, ki je navadno kos hlebca, blok sira (klada). Tak vzorec se pri senzoričnem ocenjevanju nikakor ne more enakovredno primerjati s klasičnim poltrdim sirom v hlebcu, saj iz takega »koščka, kosa« ne moremo korektno ovrednotiti že prve lastnosti, ki je splošni IZGLED, saj ne moremo oceniti, kakšna je bila nega med zorenjem, in ali so še kake druge napake v izgledu, recimo razpoke skorje, zato take »enote« sirov enostavno ne morejo biti razvrščene v skupino »poltrdih sirov«, ampak v skupino »drugih izdelkov«. Da bi bile te enote sirov lahko enakovredno ocenjene kot »poltrdi sir«, je potrebno na ocenjevanje poslati cel hlebec, saj take enote sirov tudi ne ponujajo nekega reprezentativnega PREREZA, ki bi ga lahko enakovredno ocenili kot prerez pri skupini »poltrdih sirov«.",
                "Komisija se je soglasno izrekla, da na zadnjih ocenjevanjih pogreša prisotnost večjega števila »osnovnih« mleka in mlečnih izdelkov, recimo iz skupine fermentiranega mleka ali tradicionalno izdelanih sirov, ki nudijo trden temelj za nadgradnjo z novimi, inovativnimi izdelki. Ocenjevalna komisija zaznava, da gre vse preveč v smeri »hitrih« tehnologij, npr. izdelava kuhanega sira, in da se nekako opuščajo klasične tehnologije, npr. izdelava fermentiranega mleka, izdelava skute na klasičen način, s fermentacijo, in ne s toplotno obdelavo mleka in dodatkom zakisljevalnega sredstva, vedno več je blok sirov in ne hlebcev, razen nekaterih izjem.",
                "Na koncu pa se člani komisije tudi tokrat zahvaljujemo celotni ekipi Pomurskega sejma, ki vedno poskrbi za brezhibno organizacijo, da ocenjevanje poteka nemoteno. Posebna zahvala pa gre tehnični ekipi, ki skrbno sledi ritmu ocenjevalne komisije, tako z vidika brezhibne priprave vzorcev za komisijo kot tudi z vidika primernih odmorov, med katerimi lahko člani ocenjevalne komisije malo spočijejo svoja čutila.",
            ],
        },
    ],
    "medene-pijace": [
        {
            company: "Gornja Radgona, 10. julij 2024",
            title: "Komentar predsednice komisije",
            author: "Predsednica komisije Leonida Gregorič",
            content: [
                "V okviru 62. kmetijsko-živilskega sejma Agra je potekalo ocenjevanje medenih pijač. Cilj ocenjevanja je predstavitev kakovostni medenih pijač.",
                "Na ocenjevanje je prispelo 16 vzorcev medenih pijač. Razlog za majhno število vzorcev na ocenjevanju  bi lahko pripisali slabim pogojem pridelave medu in malemu  število tržnih pridelovalcev medenih pijač. Strokovna komisija je ocenila, da  2 vzorca prejmeta veliko zlato medaljo, 7 vzorcev  prejme zlato medaljo, 4 vzorci srebrno medaljo in 1 vzorec prejme bronasto medaljo ter 2 vzorca nista bila nagrajena. ",
                "Priložnost, da pridelovalci potrdijo kakovost medenih pijač  in se predstavijo potrošnikom, je vsekakor  tudi udeležba na tem ocenjevanju.",
                "44. mednarodno ocenjevanje mesa Oce in mesnih iz delkov je izmerilo visoko kvaliteto še večjega šte vila izdelkov. 37. mednarodno ocenjevanje mleka in mlečnih izdelkov je prineslo bero proizvajalcev in proiz vodov v obsegu najboljših sejemskih let. 27. mednarodno ocenjevanje sokov, brezalko holnih pijač in embaliranih vod je privabilo še več sodelujočih držav in podjetij kot v prej. venija Gornja Radgona” in 13. odprto državno 49. odprto državno ocenjevanje vin Vino Slo- ocenjevanje BIO vin ponujata pridelek, ki je razen po kakovosti izjemen tudi po številu, saj sta zbrala preko 600 vzorcev.",
                "Zaradi premajhnega  števila vzorcev v vsaki kategoriji medenih pijač šampion ni bil podeljen.",
            ],
        },
    ],
    med: [
        {
            company: "Gornja Radgona, 7. avgust 2024",
            title: "Komentar predsednice komisije",
            author: "Predsednica komisije mag. Amalija Božnar",
            content: [
                "Na ocenjevanje medu v okviru sejma Agra 2024, je letos prispelo 142 vzorcev. Med je bil razdeljen v 8 vrst. Ocenjevali sta dve komisiji po veljavnem pravilniku o ocenjevanju medu Čebelarske zveze Slovenije.",
                "Lahko rečem, da so bili vzorci »težki«. Veliko je bilo netipičnih lastnosti za posamezno sorto.",
                "Kljub temu so končni rezultati lepi, saj je zlato medaljo dobilo 22,5 % vzorcev, srebrno 47,9 % in bronasto 20,4%.",
                "Brez odličja je ostalo le 9,2 % vzorcev, kar je manj, kot v lanski sezoni.",
                "Glavni vzrok za to, da so nekateri vzorci ostali brez odličja je to, da v medu nismo našli tipičnih organoleptičnih lastnosti za posamezno vrsto, oziroma so bile lastnosti prešibko izražene glede na deklarirano vrsto.",
                "Od 142 vzorcev smo izločili samo 1 vzorec, saj je vsebnost vode presegala, po pravilniku predpisano, dovoljeno količino.",
                "Člani ocenjevalne komisije smo svoje rezultate vpisovali direktno v računalniški program, kar je odlično, saj se s tem načinom dela izognemo napakam pri prepisovanju rezultatov iz papirja v računalnik in ocenjevanje poteka hitreje.",
                "Vsem prejemnikom odličij čestitam v imenu članov ocenjevalne komisije, kakor tudi v svojem imenu.",
            ],
        },
    ],
    "mesni-izdelki": [
        {
            company: "21. junij 2024",
            title: "MESNI IZDELKI (A, B, C)",
            author: "Predsednik komisije Prof.dr. Božidar Žlender",
            content: [
                "Na 45. mednarodnem ocenjevanju mesnih izdelkov je sodelovalo skupaj 44 podjetij (43 v preteklem letu), ki so dali v pregled kakovosti skupaj 370 vzorcev mesnin iz kategorije standardni izdelki 336 vzorcev, novi izdelki 21 vzorcev in varovalne mesnine 13 vzorcev. Veliko večino izdelkov so posredovala slovenska podjetja (42) in le majhen del hrvaška podjetja (2). Podobno kot preteklo leto z vzorci ni bilo udeleženih drugih držav iz Evrope.",
                "Nagrajenih je tudi letos zelo visok delež vzorcev in sicer med standardnimi izdelki 323 (96,1%), med novimi izdelki je nagrajenih 21 izdelkov (100%) in med varovalnimi mesninami je nagrajenih 13 izdelkov (100%). Zelo visok delež nagrajenih izdelkov je podoben kot v preteklem letu in kaže na to, da proizvajalci za ocenjevanje odberejo le najboljše izdelke.",
                "Med standardnimi izdelki so podeljena naslednja priznanja: 16 šampionov kakovosti, ki so že tretje leto zapored ocenjeni za najvišjo kakovost (zlata medalja). Takim izdelkom kupec lahko zaupa, da so ti šampioni resnično trajne kakovosti. Z zlato medaljo je ocenjenih 87 mesnin, kar je prav tako odličen visok delež izdelkov.",
                "Med novimi izdelki, ki so se prvič pojavili v proizvodnji v preteklem letu od predzadnjega ocenjevanja je bilo nagrajenih vseh 21 izdelkov s štirimi zlatimi, 16 srebrnimi in 1 bronasto medaljo. Praktično so bili vsi izdelki v konkurenci nagrajeni. Odlično!",
                "Varovalne mesnine, ki so po svoji sestavi in uporabljenih aditivih bolj varne v naši prehrani, je bilo nagrajenih vseh 13 izdelkov, od tega 3 zlate, 8 srebrnih in 1 bronasta medalja. Pri teh mesninah bi proizvajalci morali biti še bolj pozorni pri njihovi promociji za bolj zdravo prehrano, ki je vedno bolj aktualna v prehranskem smislu.",
                "Vsem dobitnikom medalj in priznanj za kakovost mesnin iskreno čestitamo. Priznanja in nagrade naj bodo spodbuda, da bo kakovost izdelkov še naprej visoka, da bo ohranjena iniciativa in inovativnost pri razvoju novih izdelkov in bolj zdravih varovalnih mesnin. Vsekakor naj kakovost mesnin sledi napotkom moderne in bolj zdrave prehrane.",
            ],
        },
        {
            company: "21. junij 2024",
            title: "SVEŽE MESO IN MESNI PRIPRAVKI (D)",
            author: "Predsednica komisije doc. dr. Mateja Lušnic Polak",
            content: [
                "Na letošnjem ocenjevanju kakovosti mesa in mesnih pripravkov je sodelovalo 23 podjetij, 22 iz Slovenije in eno iz Hrvaške. Skupno število vzorcev je bilo podobno kot prejšnje leto, med njimi je bilo tudi nekaj kolekcij.",
                "V ocenjevanje so bili vključeni izdelki iz štirih skupin: velikoprodajni kosi pakiranega mesa, maloprodajni kosi pakiranega mesa, večji in kakovostni kosi razsoljenega (prekajenega) mesa ter oblikovano presno in začinjeno meso. V skupini 6.2 (maloprodajni kosi pakiranega mesa) smo poleg perutnine, svinjine in govedine letos v ocenjevanje prejeli tudi jagnječje meso.",
                "Šampionov kakovosti je bilo 3,73 %, kar je nekoliko manj kot zadnji dve leti. Zlato medaljo za tržno ponudbo je prejelo 54 izdelkov (40,30 %), srebrno medaljo 64 izdelkov (47,76 %) in bronasto medaljo 11 izdelkov (8,21 %). Brez nagrade je ostal en izdelek, eden pa je bil iz ocenjevanja izločen. V splošnem lahko zaključimo, da je bila kakovost velike večine ocenjenih izdelkov nadpovprečna.",
                "Med pogostimi napakami so bile slabša funkcionalnost embalaže, kar se navezuje predvsem na možnost enostavnega odpiranja, napake v kakovosti presnega izdelka (krvavitve, izceja, oblikovanje/obdelava), pri mesnih pripravkih pa tudi prevelika slanost ter neharmoničnost vonja in arome. Kar 80,6 % vseh vzorcev je bilo iz skupine oblikovano in začinjeno presno meso - mešanice mariniranih koščkov mesa (predvsem piščančjega), čevapčiči, pleskavice, burgerji, zoreno goveje meso, tudi nekaj vzorcev presnih zamrznjenih čevapčičev. Omenjena skupina je prejela 3 šampione in 48 zlatih medalj.",
                "Število prijavljenih izdelkov, ki se prodajajo pod različnimi trgovskimi znamkami, je bilo podobno lanskemu. Priznanje za tržno ponudbo mesa je s 13 zlatimi, 14 srebrnimi, dvema bronastima in enim šampionom ponovno prejelo podjetje Celjske mesnine d.o.o.",
                "Zahvaljujemo se vsem sodelujočim, proizvajalcem pa čestitamo za dobljena priznanja!",
            ],
        },
    ],
    sokovi: [
        {
            company: "Gornja Radgona, 11. junij 2024",
            title: "Komentar predsednika komisije",
            author: "Predsednik komisije izr. prof. dr. Tomaž POŽRL",
            content: [
                "Sicer smo na 28. mednarodnem ocenjevanju sokov, brezalkoholnih pijač in embaliranih vod zabeležili nekoliko manjše število vzorcev kot v lanskem letu, vendar je svoje izdelke predstavilo veliko število podjetij iz treh držav, kar potrjuje pomen ocenjevanja v regiji. Tudi letos smo ocenjevali izdelke iz različnih kategorij kot so sadni sokovi in nektarji, sirupi, pijače in embalirane vode. Velik delež sokov in nektarjev še vedno predstavljajo jabolčni sokovi in sokovi iz aronije. Ocenili smo širok nabor pijač in gaziranih pijač z različnimi vsebnostmi sladkorja oz. z dodatkom sladil, pri katerih je potrebno izpostaviti paleto všečnih kombinacij okusov. Podobna raznolikost je bila opazna tudi pri kategoriji sirupov. Potrdili smo kakovost različnih vrst embaliranih vod in vod z okusom. Poleg tega je potrebno omeniti tudi ponudbo atraktivnih energijskih pijač in kvalitetnih rastlinskih napitkov, med katerimi smo letos nagradili tudi oba šampiona kakovosti.",
                "Kot vsako leto je letošnje ocenjevanje ponudilo tudi nekaj novosti kot so npr. vzorci brezalkoholnih pijač na osnovi ingverja in druge. Komisija je na 28. mednarodnem ocenjevanju podelila 2 šampiona kakovosti, 17 velikih zlatih medalj in 34 zlatih medalj, kar potrjuje kakovost ocenjenih izdelkov.",
            ],
        },
    ],
    vino: [
        {
            company: "Gornja Radgona, 11. junij 2024",
            title: "Komentar predsednika ocenjevalnih komisij",
            author: "Predsednik ocenjevalnih komisij Vino Slovenija Gornja Radgona univ. dipl. inž. živ. teh. Boštjan ZIDAR",
            content: [
                "»Častitljivih 50«. Ja, že toliko let ima ocenjevanje Vino Slovenija Gornja Radgona, ki ga organizira Pomurski sejem. Veliko korakov je potrebnih, da se prehodi takšna pot, ki je kdaj gladka kdaj pa tudi strma in težka. Vsekakor čestitke organizatorju, da je to najpomembnejše mednarodno vinsko ocenjevanje v Sloveniji znal skozi vsa ta leta pripeljati do »Abrahama«. Iskrena zahvala pa gre tudi vinarjem. Brez njih in njihovega prizadevanja za ustvarjanje izjemnih vin današnjega dne ne bi bilo. ",
                "Na letošnjem ocenjevanju smo ocenjevali vina kar 139 pridelovalcev, ki so v ocenjevanje zaupali 633 vzorcev svojih najboljših vin. Poleg tega je sodelovalo tudi 9 pridelovalcev ekoloških - BIO vin, ki so prispevali 18 vzorcev. Vina so letos prispela iz štirih držav in sicer Slovenije, Avstrije, Hrvaške in Bolgarije. Ta mednarodna udeležba je dokaz, da ima to ocenjevanje čedalje večji ugled tudi zunaj meja naše domovine.",
                "Ocenjevanje je potekalo 3 dni v 4 komisijah, ki so bile sestavljene iz 5 ocenjevalcev in predsednika. Tu gre zahvala tudi vsem predsednikom in članom komisije, ki so s svojim znanjem in izkušnjami poskrbeli za strokovno in objektivno ocenjevanje. Mednarodna zasedba ocenjevalcev je imela tudi letos odgovorno in težko delo, saj je morala med splošno zelo dobrimi vzorci, za šampionske nazive izbrati res tiste najboljše, kar pa ni bilo enostavno.",
                "Kakovost vin, katera prihajajo na to ocenjevanje je iz leta v leto boljša. Dokaz za to je tudi število prejetih odličij, ki smo jih letos podelili. Lahko bi dejal, da se v vseh kategorijah vin lepo odraža tudi napredek slovenskega vinogradništva in vinarstva tako v kvaliteti vina kot tudi v sledenju mednarodnih trendov vinskega trga. Ker pa je ta panoga v veliki meri odvisna od narave je pomembno, da se pridelovalci znamo prilagajati tudi na njene spremembe s katerimi nas skoraj vsako leto preseneti. To ostaja tudi v bodoče velik izziv za vse pridelovalce.",
                "Letos je lepa bira odličij dokaz da so vina, ki smo jih ocenjevali s svojo kvaliteto navdušila tako domače kot tudi tuje ocenjevalce.",
                "Na letošnjem ocenjevanju so se odlično odrezale penine, ki so očarale s svojo eleganco in prefinjenostjo. Najbolj pa nas je navdušila penina, ki prihaja iz največje peničarske kleti v Sloveniji in tako ohranila primat iz lanskoletnega ocenjevanja. Torej za praznovanje 50. obletnice ocenjevanja ne bo potrebno daleč po najboljšo penino. ",
                "Posebno pozornost so letos pritegnila tudi vina z oznako PTP. Posebej bi izpostavil Cviček PTP in Teran PTP katera sta bila na letošnjem ocenjevanju dobro zastopana z res dobrimi vzorci in imata letos tudi vsak svoj šampionski naziv.",
                "Tako bela kot tudi rdeča suha vina letnika 2023 so kljub težkemu letniku postregla z lepimi vzorci in s svojo kvaliteto očarala marsikaterega ocenjevalca. Bela so navduševala s svojo sočno sadnostjo in svežino, rdeča vina pa so izstopala z intenzivnimi barvami, sadnostjo, eleganco ter lepo uglajenimi tanini. Pri suhih vinih starejših letnikov, pa smo uživali v polnosti in kompleksnosti, ki nam jih  pričarajo le najboljše lege, dobri letniki in odlično izpeljane vinifikacije. ",
                "Tudi vina aromatičnih sort so letos pokazala lep potencial in nas navduševala tako v vonju kot tudi v okusih.",
                "Plemenitost je bila na strani predikatov in naravnih posebnih vin, ki so s svojo edinstvenostjo in posebnostmi dodala piko na i našemu ocenjevanju.",
                "Naj se na tem mestu iskreno zahvalim in čestitam vsem prejemnikom medalj. Vaš trud in predanost sta bila na ocenjevanju nagrajena z zasluženimi priznanji. Še posebej bi rad čestital vinarjem, ki so prejeli šampionske naslove. Ti naslovi so vrhunec vašega dela in dokaz, da se trud in strast izplačata. Vse prejete medalje naj vam bodo v ponos in vzpodbudo, saj izkazujejo visoko kvaliteto pridelanega vina in priznanje za ves trud, katerega ste vložili v pridelavo žlahtne kapljice.",
                "Prejemnikom nagrad želim, da te nagrade čim bolje marketinško izkoristite ter vina, ki jih nosijo čim bolje prodate.",
                "Vino je umetnost, ki očara in povezuje ljudi. Naj bo delo vinogradnikov in vinarjev še naprej usmerjeno k ohranjanju te umetnosti za prihodnje generacije.",
                "Zato vsem vinogradnikom in vinarjem želim dobro letino in lepo trgatev 2024",
                
            ],
        },
    ],
    mehanizacija: {
        noInfo: "Za to kategorijo še ni komentarja.",
    },
    "slo-oskar-za-embalazo": [{
        title: "Komentar predsednika žirije",
        author: "Predsednik žirije dr. David RAVNJAK",
        content: [""],
        
    },
    {
        author: "Interaktivna trajnostna embalaža Trglav – Dodopack d.o.o.",
        content: [
            "Izhodišče za zasnovo embalaže je raziskava, ki upošteva vidike izbire materialov, izdelave embalaže, njene uporabe ter njene ponovne uporabe (v funkciji novega izdelka) oziroma reciklaže. Rezultat je oblikovno zanimiva serija embalažnih izdelkov za pakiranje manjših serij, kjer je kot material uporabljen rjav karton, na katerem vlogo promocijske grafične podobe prevzame QR koda z dostopom do interaktivnih vsebin. Embalaža spodbuja nov pristop mlajših generacij k oblikovanju, pri čemer je z uporabo digitalnih vsebin in izbire materiala poudarjena njena primernost za ponovno uporabo in / ali reciklažo.",
        ],
    }
    ,
    {
        author: "Univerzalna zaščita gospodinjskih mešalnikov – DS Smith Slovenija d.o.o.",
        content: [
            "Embalaža – vložni element iz kartona – je zasnovana s ciljem zamenjave EPS zaščitnih elementov pri pakiranju malih gospodinjskih aparatov (električnega mešalnika). Tehnično zahtevna in kompleksna konstrukcija vložnega elementa iz enega materiala, brez lepljenih mest ali kovinskih pritrdil ustrezno varuje izdelek, hkrati pa zmanjšuje raznolikost embalažnih elementov, saj je namenjena pakiranju treh različnih modelov mešalnika. Učinkovito nadomešča zaščitno embalažo iz EPS in predstavlja embalažno rešitev z visoko stopnjo reciklabilnosti.",
        ],
    }
    ,
    {
        author: "Embalaža za Medexov matični mleček Gelee Royale Forte – Jamnik d.o.o.",
        content: [
            "Skupinska in posamična embalaža za steklene fiole z matičnim mlečkom sledi trajnostnim trendom na način, da je za izdelavo embalaže uporabljen bel karton, z relativno malo potiskanimi površinami ob tem, da dizajn embalaže grafično še vedno sledi ostalim produktnim linijam naročnika. Poleg tega embalaža vsebuje minimalno število lepljenih mest, za dostop do izdelkov pa učinkovito uporablja kombinacijo sestavljivih in izvlečnih elementov, ki lahko služijo tudi kot stojalo za izdelke med uporabo. Z vidika učinkovite izrabe prostornine, embalaža predstavlja dobro rešitev, saj ne vsebuje veliko praznega prostora in zato omogoča pakiranje večjega števila izdelkov na prostorsko enoto embalaže.",
        ],
    }
    ,
    {
        author: "Sklop produktov iz biokompozitov – RUTENA d.o.o.",
        content: [
            "Embalaža iz biokompozitov na osnovi glivnega micelija predstavlja tehnološko novost v Sloveniji. Namen njene uporabe je zamenjava EPS embalažnih elementov z bioosnovanim in biorazgradljivim materialom z dobrimi izolacijskimi, mehanskimi, absorpcijskimi ter zaščitnimi lastnostmi. Oblika embalaže je prilagodljiva s pomočjo uporabe 3D modelov in priprave ustreznih kalupov uporabljenih v fazi izdelave embalaže. Sam proces izdelave (rasti) embalaže je energijsko nezahteven, uporabljeni substrati pa so stranski ali odpadni tokovi iz lesne ali agroživilske proizvodnje. Predstavljen koncept embalaže predstavlja pionirsko delo v slovenskem prostoru.",
        ],
    }
    ,
    {
        title: "Nosilec",
        author: "Zaščita avtomobilskih nosilcev koles – DS Smith Slovenija d.o.o.",
        content: [
            "Industrijska embalaža s premišljeno zasnovo omogoča uporabo večslojnega valovitega kartona za pakiranje težjih industrijskih izdelkov. Prednost embalaže je uporaba monomateriala (valovitega kartona) za glavno embalažo in za optimalno oblikovane vložne elemente iz zgibanega kartona, v kombinaciji z leseno paleto in polimernimi veznimi trakovi. Konstrukcija zagotavlja veliko nosilnost embalaže, njeno enostavno sestavljanje in optimalno izrabo prostora pri kontejnerskem transportu, po uporabi pa enostavno ločevanje materialov ter njihovo ponovno uporabo (palete) ali reciklažo (valoviti karton). Dodatna prednost izbora zgibane (zložljive) kartonske embalaže je tudi njen majhen transportni volumen od točke izdelave embalaže do točke njene uporabe. Celotna embalaža predstavlja celovit pristop k zahtevnemu pakiranju industrijskih izdelkov ob upoštevanju načel krožnega gospodarstva.",
        ],
    }
    ],
};
