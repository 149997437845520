import React from "react";

interface TableProps {
    data: any;
    headers: string[];
}


const Table: React.FC<TableProps> = ({ data, headers }) => {
    
    return (
        <table className="records-table">
            <thead>
                <tr className="row">
                    {headers.map((header) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((data: any, index: number) => (
                    <tr key={index}>
                        <td>{data.product}</td>
                        <td>{data.company}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
