import React, { useEffect, useState } from 'react'

import MainSection from './ImageSection'
import TableSection from './TableSection';


const Products = () => {
    const [data, setData] = React.useState<any>([]);

    useEffect(() => {
        fetch("/api/v1/awards",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({years: 2024})

        })
        .then((resp) => resp.json())
        .then((awards) => {
            if (!awards.success) {
                throw awards.reason;
            }
            setData(awards.response);
            console.log("getting awards",awards);
        })
        .catch((e) => {
            console.error(e);
        });

    }, []);

    return (
        <div>
            <MainSection title={"Pregled vseh nagrad"} year={"2024"}/>

            {/* SEARCH SECTION */}
            <div className='searchSection'>
                <div>
                    <a href="/" style={{color:"#F49F21"}}><svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#F49F21" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg> Nazaj na Katalog Nagrad</a>
                </div>

                <TableSection data={data} />
                
            </div>
            
        </div>
    )
}

export default Products
