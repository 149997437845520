import React from "react";
import { useNavigate } from "react-router-dom";

import CategoryCard from "./CategoryCard";

/* Data */
import {dataCategories} from "../Data/dataCategories";

const CategoryOverview = () => {
    const navigate = useNavigate();
   

    return (
        <div className="category-section">
            
            <p className="title-section">PREGLED PO KATEGORIJAH</p>

            <div className="info-section">    
                <div className="category-cards">
                    {dataCategories.map((element:any, index:any) => {
                        return(
                            <CategoryCard data={element} key={index}/>
                        )
                    })}
                </div>

                <div className="about">
                    <div className="about ">
                        <button className="btn-secondary add-space" onClick={() =>  navigate(`/program-council`)} aria-labelledby="programski svet">PROGRAMSKI SVET</button>
                        <button className="btn-secondary" onClick={() =>  navigate(`/editorial`)} aria-labelledby="uvodnik">Uvodnik</button>  
                    </div>
                    
                    <button className="btn-primary" onClick={() =>  navigate(`/products`)} aria-labelledby="search all products">Išči med vsemi izdelki</button>
                </div>
            </div>
        
        </div>
    );
};

export default CategoryOverview;
