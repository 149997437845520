import React from "react";
import SubPageImageSection from "../SubPageImageSection";
import { backGroundTitle } from "../Data/dataCategories";
import BackToHome from "../BackToHome";
import {articles} from "./data";
import Article from "./Article";


function Editorial() {
    let url = window.location.pathname;
    return (
        <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="editorial-section">
                <BackToHome title="Nazaj na Katalog nagrad" />
                {articles.map((article, index) => (
                    <Article
                        key={index}
                        company={article.company}
                        title={article.title}
                        author={article.author}
                        content={article.content}                        
                    />
                ))}
            </div>
        </>
    );
}

export default Editorial;