/* ICONS */
import milk from "../../image/category-icons/milk.png";
import milk2 from "../../image/category-icons/milk2.png";
import meat from "../../image/category-icons/meat.png";
import meat2 from "../../image/category-icons/meat2.png";
import honey from "../../image/category-icons/honey.png";
import honey2 from "../../image/category-icons/honey2.png";
import tractor from "../../image/category-icons/tractor.png";
import tractor2 from "../../image/category-icons/tractor2.png";
import wine from "../../image/category-icons/wine.png";
import wine2 from "../../image/category-icons/wine2.png";
import honeyDrink from "../../image/category-icons/honey-drink.png";
import honeyDrink2 from "../../image/category-icons/honey-drink2.png";
import juice from "../../image/category-icons/juice.png";
import juice2 from "../../image/category-icons/juice2.png";
import prize from "../../image/category-icons/prize.png";
import prize2 from "../../image/category-icons/prize2.png";

/* BG IMAGES */
import productsBG from "../../image/products-bg.png";
import milkBG from "../../image/milk-bg.png";
import meatBG from "../../image/meat-bg.png";
import honeyBG from "../../image/honey-bg.png";
import tractorBG from "../../image/tractor-bg.png";
import wineBG from "../../image/wine-bg.png";
import honeyDrinkBG from "../../image/honey-drink-bg.png";
import juiceBG from "../../image/juice-bg.png";
import prizeBG from "../../image/prize-bg.png";
import generalBG from "../../image/general-bg.png";

/* Medal Images */
import bigGoldMedal from "../../image/medals/velika-zlata-medalja-slo@2x.png";
import bigGoldMedalENG from "../../image/medals/velika-zlata-medalja-eng@2x.png";
import goldMedal from "../../image/medals/zlata-medalja-slo@2x.png";
import goldMedalENG from "../../image/medals/zlata-medalja-eng@2x.png";
import silverMedal from "../../image/medals/srebrna-medalja-slo@2x.png";
import silverMedalENG from "../../image/medals/srebrna-medalja-eng@2x.png";
import bronzeMedal from "../../image/medals/bronasta-slo@2x.png";
import bronzeMedalENG from "../../image/medals/bronasta-eng@2x.png";
import champion from "../../image/medals/champion-slo@2x.png";
import championENG from "../../image/medals/champion-eng@2x.png";
import specialMedal from "../../image/medals/posebno-priznanje-slo@2x.png";
import specialMedalENG from "../../image/medals/posebno-priznanje-slo@2x.png";


export let dataCategories = [
    {
        title:"Mleko in Mlečni Izdelki",
        link:"/2024/mlecni-izdelki",
        icon:milk,
        icon2:milk2,
    },
    {
        title:"Meso in Mesni izdelki",
        link:"/2024/mesni-izdelki",
        icon:meat,
        icon2:meat2,
    },
    {
        title:"Med",
        link:"/2024/med",
        icon:honey,
        icon2:honey2,
    },
    {
        title:"Kmetijska mehanizacija in oprema",
        link:"/2024/mehanizacija",
        icon:tractor,
        icon2:tractor2,
    },
    {
        title:"Vino",
        link:"/2024/vino",
        icon:wine,
        icon2:wine2,
    },
    {
        title:"Medene Pijače",
        link:"/2024/medene-pijace",
        icon:honeyDrink,
        icon2:honeyDrink2,
    },
    {
        title:"Sokovi, Pijače in Embalirane Vode",
        link:"/2024/sokovi",
        icon:juice,
        icon2:juice2,
    },
    {
        title:"Slovenski oskar za embalažo",
        link:"/2024/slo-oskar-za-embalazo",
        icon:prize,
        icon2:prize2,
    }
]

let bgImages = {
    "/products":productsBG,
    "/2024/mlecni-izdelki":milkBG,
    "/2024/mesni-izdelki":meatBG,
    "/2024/med":honeyBG,
    "/2024/mehanizacija":tractorBG,
    "/2024/vino": wineBG,
    "/2024/medene-pijace":honeyDrinkBG,
    "/2024/sokovi":juiceBG,
    "/2024/slo-oskar-za-embalazo":prizeBG,
    "/program-council":generalBG,
    "/editorial":generalBG,
    "/2024/mlecni-izdelki/record":generalBG,
    "/2024/mlecni-izdelki/commentary":generalBG,
    "/2024/medene-pijace/record":generalBG,
    "/2024/medene-pijace/commentary":generalBG,
    "/2024/med/record":generalBG,
    "/2024/med/commentary":generalBG,
    "/2024/mesni-izdelki/record":generalBG,
    "/2024/mesni-izdelki/commentary":generalBG,
    "/2024/vino/commentary":generalBG,
    "/2024/vino/record":generalBG,
    "/2024/slo-oskar-za-embalazo/commentary":generalBG,
    "/2024/slo-oskar-za-embalazo/record":generalBG,
    "/2024/mehanizacija/commentary":generalBG,
    "/2024/mehanizacija/record":generalBG,
    "/2024/sokovi/commentary":generalBG,
    "/2024/sokovi/record":generalBG,
}

let bgTitle = {
    "/2024/mlecni-izdelki":"Mleko in Mlečni Izdelki",
    "/2024/mesni-izdelki":"Meso in Mesni izdelki",
    "/2024/med":"Med",
    "/2024/mehanizacija":"Kmetijska mehanizacija in oprema",
    "/2024/vino": "Vino",
    "/2024/medene-pijace":"Medene Pijače",
    "/2024/sokovi":"Sokovi, Pijače in Embaliranje Vode",
    "/2024/pijace":"Sokovi, Pijače in Embaliranje Vode",
    "/2024/slo-oskar-za-embalazo":"Slovenski oskar za embalažo",
    "/program-council":"Programski svet",
    "/editorial":"Uvodnik",
    "/2024/mlecni-izdelki/record":"Zapisnik",
    "/2024/medene-pijace/record":"Zapisnik",
    "/2024/med/record":"Zapisnik",
    "/2024/mesni-izdelki/record":"Zapisnik",
    "/2024/vino/record":"Zapisnik",
    "/2024/sokovi/record":"Zapisnik",
    "/2024/slo-oskar-za-embalazo/record":"Zapisnik",
    "/2024/mehanizacija/record":"Zapisnik",
    "/2024/mlecni-izdelki/commentary":"Komentar predsednice ocenjevalne komisije",
    "/2024/medene-pijace/commentary":"Komentar predsednice ocenjevalne komisije",
    "/2024/med/commentary":"Komentar predsednice ocenjevalne komisije",
    "/2024/mesni-izdelki/commentary":"Komentar predsednikov komisij",
    "/2024/sokovi/commentary":"Komentar predsednika komisije",
    "/2024/mehanizacija/commentary":"Komentar predsednika komisije",
    "/2024/slo-oskar-za-embalazo/commentary":"Komentar predsednika komisije",
    "/2024/vino/commentary":"Komentar predsednika komisije",
}

let tableData = {
    "/2024/mlecni-izdelki":"mlecni-izdelki",
    "/2024/mesni-izdelki":"mesni-izdelki",
    "/2024/med":"med",
    "/2024/mehanizacija":"mehanizacija",
    "/2024/vino": "vino",
    "/2024/medene-pijace":"medene-pijace",
    "/2024/sokovi":"sokovi",
    "/2024/slo-oskar-za-embalazo":"slo-oskar-za-embalazo",
}

let countryCode = {
    'Slovenija':'SI',
    'Hrvaška':'HR',
    'Avstrija':'AT',
    'Slovaška':'SK',
    'Madžarska':'HU',
    'Bolgarija':'BG',
}

export let medals={
    "VELIKA ZLATA MEDALJA": {
        icon:bigGoldMedal,
        iconEng:bigGoldMedalENG,
    },
    "ZLATA MEDALJA":{
        icon:goldMedal,
        iconEng:goldMedalENG,
    },
    "ZLATA MEDALJA ZA TRŽNO PONUDBO":{
        icon:goldMedal,
        iconEng:goldMedalENG,
    },
    "SREBRNA MEDALJA":{
        icon:silverMedal,
        iconEng:silverMedalENG,
    },
    "SREBRNA MEDALJA ZA TRŽNO PONUDBO":{
        icon:silverMedal,
        iconEng:silverMedalENG,
    },
    "BRONASTA MEDALJA":{
        icon:bronzeMedal,
        iconEng:bronzeMedalENG,
    },
    "BRONASTA MEDALJA ZA TRŽNO PONUDBO":{
        icon:bronzeMedal,
        iconEng:bronzeMedalENG,
    },
    "ŠAMPION":{
        icon:champion,
        iconEng:championENG,
    },
    "POSEBNO PRIZNANJE":{
        icon:specialMedal,
        iconEng:specialMedalENG,
    },
}

export function backGroundImage(link){
    return bgImages[link];
}

export function backGroundTitle(link){
    return bgTitle[link];
}

export function getTableData(link){
    return tableData[link];
}

export function getCountry(country){
    return countryCode[country];
}
