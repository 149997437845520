import React, { useState } from "react";
import SubPageImageSection from "../SubPageImageSection";
import { backGroundTitle } from "../Data/dataCategories";
import BackToHome from "../BackToHome";
import { commentary } from "./data";
import Article from "../Editorial/Article";

function RespectiveCommentary() {
    let url = window.location.pathname;
    let category = url.split("/")[2];
    let backLink = url.replace("/commentary", "");
    const [data, setData] = useState<any>(
        commentary[category as keyof typeof commentary]
    );
    return (
        <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="editorial-section">
                <BackToHome
                    title={"Nazaj na " + backGroundTitle(backLink)}
                    link={backLink}
                />
                {data.noInfo && <h1 className="no-info">{data.noInfo}</h1>}
                {!data.noInfo && (
                    <>
                        {data.map((data: any, index:number) => (
                            <>
                                <Article
                                    company={data.company}
                                    title={data.title}
                                    author={data.author}
                                    content={data.content}
                                    key={index}
                                />
                                <div className="divider"></div>
                            </>
                        ))}
                    </>
                )}
            </div>
        </>
    );
}

export default RespectiveCommentary;
