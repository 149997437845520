import React, { useState } from 'react'

import SubPageImageSection from "../SubPageImageSection";
import BackToHome from "../BackToHome";
import { backGroundTitle } from "../Data/dataCategories";
import { records } from "./data";
import Member from '../ProgramCouncil/Member';
import DynamicTable from './DynamicTable';
import Stats from './Stats';
import TwoDTable from './TwoDTable';

const SloOskarRecord = () => {
    let url = window.location.pathname;
    let category = url.split("/")[2];
    let backLink = url.replace("/record", "");
    const [data, setData] = useState<any>(
        records[category as keyof typeof records]
    );

  return (
    <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="record-section">
                <BackToHome
                    title={"Nazaj na " + backGroundTitle(backLink)}
                    link={backLink}
                />
                
                {/*data.noInfo && <h1 className="no-info">{data.noInfo}</h1>*/}
                {!data.noInfo && (
                    <>
                        <p className="foreword">{data.foreword}</p>
                        <h1>Strokovna žirija</h1>
                        <h2>Predsednik/ca</h2>
                        <div className="members">
                            {data.president.map(
                                (
                                    president: {
                                        name: string;
                                        position: string;
                                    },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={president.name}
                                        position={president.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>
                        <h2>Člani</h2>
                        <div className="members">
                            {data.members.map(
                                (
                                    member: { name: string; position: string },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={member.name}
                                        position={member.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>

                        <h1>OBRAZLOŽITVE NOMINACIJ</h1>

                        <p>Namen natečaja Slovenski oskar za embalažo je oceniti in nagraditi najuspešnejše dosežke na
                        področju vseh vrst embalaže, njenih sestavnih delov, prototipov, izumov in izboljšav. Pomurski
                        sejem s svojimi sejemskimi projekti in z najrazličnejšimi informacijskimi potmi širi poznavanje
                        nagrajenih načrtovalcev, proizvajalcev in uporabnikov embalaže v slovenskem in mednarodnem
                        prostoru. Končno poslanstvo projekta je ob promocijskih vidikih predvsem to, da bi prispeval k
                        boljšemu odnosu med embalažo, izdelkom, logistiko, med proizvajalci in končnimi uporabniki,
                        ter k večjemu sožitju med človekom in okoljem. Javni natečaj SLOVENSKI OSKAR ZA
                        EMBALAŽO je odprt za proizvajalce in uporabnike embalaže, konstruktorje, izumitelje in avtorje
                        tehničnih izboljšav iz Republike Slovenije in iz drugih držav.</p>

                        <p>Pomurski sejem d.o.o. Predsednik uprave</p>
                        

                        <h2>NOSILEC NASLOVA 40. SLOVENSKI OSKAR ZA EMBALAŽO 2024</h2>

                        <p>IZDELEK: <b>Zaščita avtomobilskih nosilcev koles</b></p>
                        <p>PRIJAVITELJ: <b>DS Smith Slovenija d.o.o.</b></p>
                        <p>AVTOR: <b>Miha Toplišek</b></p>

                        <p className="subtitle">Industrijska embalaža s premišljeno zasnovo omogoča uporabo večslojnega valovitega kartona
                        za pakiranje težjih industrijskih izdelkov. Prednost embalaže je uporaba monomateriala
                        (valovitega kartona) za glavno embalažo in za optimalno oblikovane vložne elemente iz
                        zgibanega kartona, v kombinaciji z leseno paleto in polimernimi veznimi trakovi. Konstrukcija
                        zagotavlja veliko nosilnost embalaže, njeno enostavno sestavljanje in optimalno izrabo prostora
                        pri kontejnerskem transportu, po uporabi pa enostavno ločevanje materialov ter njihovo
                        ponovno uporabo (palete) ali reciklažo (valoviti karton). Dodatna prednost izbora zgibane
                        (zložljive) kartonske embalaže je tudi njen majhen transportni volumen od točke izdelave
                        embalaže do točke njene uporabe. Celotna embalaža predstavlja celovit pristop k zahtevnemu
                        pakiranju industrijskih izdelkov ob upoštevanju načel krožnega gospodarstva.</p>


                        <h2>NOMINACIJA ZA NASLOV 40. SLOVENSKI OSKAR ZA EMBALAŽO 2024</h2>

                        <p>IZDELEK: <b>Interaktivna trajnostna embalaža Tr&#39;glav</b></p>
                        <p>PRIJAVITELJ: <b>DODOPACK d.o.o.</b></p>
                        <p>AVTOR: <b>Andreja Pogačar</b></p>

                        <p className="subtitle">Izhodišče za zasnovo embalaže je raziskava, ki upošteva vidike izbire materialov, izdelave
                        embalaže, njene uporabe ter njene ponovne uporabe (v funkciji novega izdelka) oziroma
                        reciklaže. Rezultat je oblikovno zanimiva serija embalažnih izdelkov za pakiranje manjših serij,
                        kjer je kot material uporabljen rjav karton, na katerem vlogo promocijske grafične podobe
                        prevzame QR koda z dostopom do interaktivnih vsebin. Embalaža spodbuja nov pristop mlajših
                        generacij k oblikovanju, pri čemer je z uporabo digitalnih vsebin in izbire materiala poudarjena
                        njena primernost za ponovno uporabo in / ali reciklažo.</p>

                        <h2>NOMINACIJA ZA NASLOV 40. SLOVENSKI OSKAR ZA EMBALAŽO 2024</h2>

                        <p>IZDELEK: <b>Univerzalna zaščita gospodinjskih mešalnikov (zamenjava stiropora)</b></p>
                        <p>PRIJAVITELJ: <b>DS Smith Slovenija d.o.o.</b></p>
                        <p>AVTOR: <b>Miha Krnc</b></p>

                        <p className="subtitle">Embalaža – vložni element iz kartona – je zasnovana s ciljem zamenjave EPS zaščitnih
                        elementov pri pakiranju malih gospodinjskih aparatov (električnega mešalnika). Tehnično
                        zahtevna in kompleksna konstrukcija vložnega elementa iz enega materiala, brez lepljenih mest
                        ali kovinskih pritrdil ustrezno varuje izdelek, hkrati pa zmanjšuje raznolikost embalažnih
                        elementov, saj je namenjena pakiranju treh različnih modelov mešalnika. Učinkovito nadomešča
                        zaščitno embalažo iz EPS in predstavlja embalažno rešitev z visoko stopnjo reciklabilnosti.</p>

                        <h2>NOMINACIJA ZA NASLOV 40. SLOVENSKI OSKAR ZA EMBALAŽO 2024</h2>

                        <p>IZDELEK: <b>Embalaža za Medexov matični mleček Gelee Royale Forte – produktna in promocijska embalaža</b></p>
                        <p>PRIJAVITELJ: <b>Jamnik d.o.o.</b></p>
                        <p>AVTOR: <b>Študentki NTF: Sara Klepac in Natalija Kaferle,
                        Mentorici: mag. graf. inž. Tanja Sinkovič in
                        univ. dipl. inž. graf. tehnol. Nina Komic</b></p>

                        <p className="subtitle">Skupinska in posamična embalaža za steklene fiole z matičnim mlečkom sledi trajnostnim
                        trendom na način, da je za izdelavo embalaže uporabljen bel karton, z relativno malo
                        potiskanimi površinami ob tem, da dizajn embalaže grafično še vedno sledi ostalim produktnim
                        linijam naročnika. Poleg tega embalaža vsebuje minimalno število lepljenih mest, za dostop do
                        izdelkov pa učinkovito uporablja kombinacijo sestavljivih in izvlečnih elementov, ki lahko služijo
                        tudi kot stojalo za izdelke med uporabo. Z vidika učinkovite izrabe prostornine, embalaža
                        predstavlja dobro rešitev, saj ne vsebuje veliko praznega prostora in zato omogoča pakiranje
                        večjega števila izdelkov na prostorsko enoto embalaže.</p>

                        <h2>NOMINACIJA ZA NASLOV 40. SLOVENSKI OSKAR ZA EMBALAŽO 2024</h2>

                        <p>IZDELEK: <b>Sklop produktov iz biokompozitov</b></p>
                        <p>PRIJAVITELJ: <b>RUTENA d.o.o.</b></p>
                        <p>AVTOR: <b>MYCOPOR</b></p>

                        <p className="subtitle">Embalaža iz biokompozitov na osnovi glivnega micelija predstavlja tehnološko novost v
                        Sloveniji. Namen njene uporabe je zamenjava EPS embalažnih elementov z bioosnovanim in
                        biorazgradljivim materialom z dobrimi izolacijskimi, mehanskimi, absorpcijskimi ter zaščitnimi
                        lastnostmi. Oblika embalaže je prilagodljiva s pomočjo uporabe 3D modelov in priprave
                        ustreznih kalupov uporabljenih v fazi izdelave embalaže. Sam proces izdelave (rasti) embalaže
                        je energijsko nezahteven, uporabljeni substrati pa so stranski ali odpadni tokovi iz lesne ali
                        agroživilske proizvodnje. Predstavljen koncept embalaže predstavlja pionirsko delo v
                        slovenskem prostoru.</p>
                        
                    </>
                )}
            </div>
        </>
  )
}

export default SloOskarRecord
