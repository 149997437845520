import React, { useEffect, useState } from "react";
import { backGroundTitle, getCountry, medals } from "../Data/dataCategories";
import BackToHome from "../BackToHome";
import grandGoldMedal from "../../image/grand-gold-medal.png";
import placeholderImage from "../../image/medal-placeholder.png";
import Country from "../Country";
import Loading from "../Loading/Loading";
import downloadImg from "../../image/download.png";

function Details() {
    let url = window.location.pathname;
    let category = url.split("/")[2];
    let backLink = "/" + url.split("/")[1] + "/" + (category == "pijace" ? "sokovi" : category);

    let id = url.split("/")[3];
    const lang = "si";

    const [loading,setLoading] = useState<boolean>(true);
    const [data,setData] = useState<any>({});
    const [validTo,setValidTo] = useState<any>("");

    const tsMedals: any = medals;

    useEffect(() => {
        fetch("/api/v1/awards/"+id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((resp) => resp.json())
            .then((awards) => {
                if (!awards.success) {
                    throw awards.reason;
                }
                setData(awards.response);
                let temp = (awards.response.details.assessment_date).split(" ")[0].split(".");
                setValidTo(temp[0]+"."+temp[1]+".2025")
                setLoading(false);
                console.log("getting awards", awards);
            })
            .catch((e) => {
                console.error(e);
                setLoading(false);
            });
    }, []);

    function downloadQr(name:any){

        let img_name = name.details.title[lang].replace(" ","_")
        fetch("/api/v1/awards/"+id+"?type=qr_code", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((resp) => resp.blob()).then((resp) => {
            let blobUrl = window.URL.createObjectURL(resp);
            let a = document.createElement('a');
            a.download = img_name+".png";
            a.href = blobUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
        
        .catch((e) => {
            console.error(e);
            setLoading(false);
        });
    }

    return (
        <div className="details-wrapper">
            
            <div className="details-section">
                <BackToHome
                    title={"Nazaj na " + backGroundTitle(backLink)}
                    link={backLink}
                />
            </div>
            {loading ? <Loading/> : <>
                <div className="banner">
                   
                    <h2>{data.details.title[lang]}</h2>
                </div>
                <div className="details-section">
                    <div className="details-left">
                        <div className="details-title">
                            <div className="origin">
                                <p>{data.details.producer}</p>
                                {/*<img alt={getCountry(data.details.country['si'])} width="24px" height="16px" src={ "https://purecatamphetamine.github.io/country-flag-icons/3x2/" + getCountry(data.details.country['si']) + ".svg" } />*/}
                            </div>
                            <h1>{data.details.product[lang]}</h1>
                            <h2>{data.details.category[lang]} - {data.details.group[lang]}</h2>
                        </div>

                        <div className="download-qr">
                            {data.sent &&
                                <button className="btn-primary" onClick={() => downloadQr(data)} aria-labelledby="download-reward"><span>PRENESI NAGRADO <img width="18" src={downloadImg} alt="alt" /></span></button>
                            }
                            
                        </div>
                        <div className="details-company">
                            <p className="default-text">PODATKI O PODJETJU</p>
                            <p className="default-text" style={{margin:"0px"}}>{data.details.address} {data.details.post_number} {data.details.post}</p>
                            {data.details.phone_number && data.details.phone_number != "0" &&
                                <p className="default-text" style={{margin:"0px"}}>Telefon: {data.details.phone_number}</p>
                            }
                            {data.details.website && data.details.website != "0" &&
                                <p className="default-text" style={{margin:"0px", cursor:"pointer", color:"black"}} onClick={() => {
                                    let url = data.details.website
                                    if(!url.startsWith("http://")) {
                                        url = "http://" + url
                                    }
                                    window.open(url);
                                }}>{data.details.website}</p>
                            }
                            
                            <p className="default-text" style={{fontWeight:"bold"}}>Izdajanje digitalne nagrade omogoča podjetje <a href="https://netis.si/" target="_blank">Netis</a> z rešitvijo <a href="https://mynext.id/" target="_blank">MyNextID</a></p>
                            {/*<p className="default-text">
                                Ptujska cesta 74, SI-2331 Pragersko Telefon: 031/613
                                430 Faks: 02/803 9091 www.hlebcek.si
                            </p>*/}
                        </div>
                    </div>
                    <div className="details-right">
                        <div className="details-medal">
                            <img src={lang == 'si' ? tsMedals[data.details.title[lang]].icon : tsMedals[data.details.title[lang]].iconEng } alt="Award image" />
                        </div>

                        <div className="details-medal-data" >
                            <div style={{padding: "13px 26px"}}>
                                <p className="default-text">Izdajatelj: <b>{data.details.issuer[lang]}</b></p>
                                <p className="default-text">Ime nagrade: <b>{data.details.title[lang]}</b></p>
                                <p className="default-text">Datum izdane nagrade: <b>{data.details.assessment_date.split(" ")[0]}</b></p>
                                <p className="default-text">Veljavnost izdane nagrade: <b>{validTo}</b></p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </>}
            
        </div>
    );
}

export default Details;
