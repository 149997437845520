import React, { useEffect, useState } from "react";
import katalog from "../image/main-bg.png";
import { backGroundImage } from "./Data/dataCategories";

const ImageSection = (props: any) => {
    
    const [bgImage, setBgImage] = useState(katalog);

    useEffect(() => {
        let path = window.location.pathname;
        if(path != "/"){
            setBgImage(backGroundImage(path));
        }
    }, []);

    return (
        <div className="main-section" style={{backgroundImage:`url(${bgImage})`}}>
            {/*<img src={katalog}></img>*/}
            <div className="title-section"  >
                <p className="main-title">{props.title}</p>
                <p className="sub-title">{props.year}</p>
            </div>
        </div>
    );
};

export default ImageSection;
