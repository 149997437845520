import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useNavigate,
} from "react-router-dom";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Catalog from "./components/Catalog/Catalog";
import Products from "./components/Products";
import RespectiveCategory from "./components/RespectiveCategory";
import BackToTop from "./components/BackToTop";
import ProgramCouncil from "./components/ProgramCouncil/ProgramCouncil";
import Editorial from "./components/Editorial/Editorial";
import MlecniIzdelkiRecord from "./components/Record/MlecniIzdelkiRecord";
import RespectiveCommentary from "./components/Commentary/RespectiveCommentary";
import Details from "./components/Details/Details";
import MedRecord from "./components/Record/MedRecord";
import MedenePijaceRecord from "./components/Record/MedenePijaceRecord";
import SokoviRecord from "./components/Record/SokoviRecord";
import MesniIzdelkiRecord from "./components/Record/MesniIzdelkiRecord";
import VinoRecord from "./components/Record/VinoRecord";
import { useEffect } from "react";
import MehanizacijaRecord from "./components/Record/MehanizacijaRecord";
import SloOskarRecord from "./components/Record/SloOskarRecord";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
        <Router>
            <Header/>
            
            <Routes>
                <Route path="/" element={<Catalog />} />
                <Route path="/products" element={<Products />} />
                <Route path="/2024/:category" element={<RespectiveCategory />} />
                <Route path="/2024/mlecni-izdelki/record" element={<MlecniIzdelkiRecord />} />
                <Route path="/2024/med/record" element={<MedRecord />} />
                <Route path="/2024/medene-pijace/record" element={<MedenePijaceRecord />} />
                <Route path="/2024/sokovi/record" element={<SokoviRecord />} />
                <Route path="/2024/mesni-izdelki/record" element={<MesniIzdelkiRecord />} />
                <Route path="/2024/vino/record" element={<VinoRecord />} />
                <Route path="/2024/mehanizacija/record" element={<MehanizacijaRecord />} />
                <Route path="/2024/slo-oskar-za-embalazo/record" element={<SloOskarRecord />} />
                <Route path="/2024/:category/commentary" element={<RespectiveCommentary />} />
                <Route path="/2024/:category/:id" element={<Details />} />            
                <Route path="/program-council" element={<ProgramCouncil />} />
                <Route path="/editorial" element={<Editorial />} />
            </Routes>

            <Footer />
            <BackToTop/>
            
        </Router>
    </ThemeProvider>
  );
}

export default App;
