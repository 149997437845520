import React, { useState } from "react";
import { records } from "./data";
import SubPageImageSection from "../SubPageImageSection";
import { backGroundTitle } from "../Data/dataCategories";
import BackToHome from "../BackToHome";
import Member from "../ProgramCouncil/Member";
import DynamicTable from "./DynamicTable";
import AwardsTable from "./AwardsTable";
import Table from "./Table";
import TwoDTable from "./TwoDTable";
import Stats from "./Stats";
import MesoAwardsTable from "./MesoAwardsTable";

function MesniIzdelkiRecord() {
    let url = window.location.pathname;
    let category = url.split("/")[2];
    let backLink = url.replace("/record", "");
    const [data, setData] = useState<any>(
        records[category as keyof typeof records]
    );

    return (
        <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="record-section">
                <BackToHome
                    title={"Nazaj na " + backGroundTitle(backLink)}
                    link={backLink}
                />

                {data.noInfo && <h1 className="no-info">{data.noInfo}</h1>}
                {!data.noInfo && (
                    <>
                        <p className="foreword">{data.foreword}</p>
                        <h1>Ocenjevalna komisija</h1>
                        <h2>Predsednik/ca</h2>
                        <div className="members">
                            {data.president.map(
                                (
                                    president: {
                                        name: string;
                                        position: string;
                                    },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={president.name}
                                        position={president.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>
                        <h2>Člani</h2>
                        <div className="members">
                            {data.members.map(
                                (
                                    member: { name: string; position: string },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={member.name}
                                        position={member.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>
                        {data.technicalLeader && (
                            <>
                                <h2>Tehnični vodja</h2>
                                <Member
                                    name={data.technicalLeader.name}
                                    position={data.technicalLeader.position}
                                />
                            </>
                        )}

                        <h1>
                            Statistični podatki o udeležencih in rezultatih
                            ocenjevanja
                        </h1>
                        {data.statistics.map(
                            (
                                statistics: any,
                                index: React.Key | null | undefined
                            ) => {
                                return (
                                    <div key={index}>
                                        <p className="foreword">
                                            {statistics.description}
                                        </p>
                                        <div className="collaborators">
                                            <DynamicTable
                                                data={statistics.collaborators}
                                                headers={[
                                                    "Država",
                                                    "Število podjetij",
                                                    "Število izdelkov",
                                                ]}
                                            />
                                            <Stats data={statistics} />
                                        </div>
                                        <p className="foreword">
                                            Podeljena odličja:
                                        </p>
                                        <MesoAwardsTable
                                            data={statistics.awards}
                                            headers={[
                                                "Nagrada",
                                                "Število nagrajenih",
                                                "Delež",
                                            ]}
                                            total={
                                                statistics.total
                                                    .numberOfProducts
                                            }
                                        />
                                        <p className="foreword">
                                            Ocenjeni izdelki po kategorijah:
                                        </p>
                                        <Table
                                            data={
                                                statistics.extras
                                                    .productsByCategory
                                            }
                                            headers={[
                                                "Kategorija",
                                                "Število izdelkov",
                                            ]}
                                        />
                                        <p className="foreword">
                                            Odličja po kategorijah:
                                        </p>
                                        {statistics.extras.awardsByCategory.map(
                                            (
                                                award: any,
                                                index:
                                                    | React.Key
                                                    | null
                                                    | undefined
                                            ) => (
                                                <>
                                                    <h3>{award.title}</h3>
                                                    <AwardsTable
                                                        data={award}
                                                        headers={[
                                                            "Nagrada",
                                                            "Število nagrajenih",
                                                            "Delež",
                                                        ]}
                                                        total={award.numbers.reduce(
                                                            (
                                                                a: number,
                                                                b: number
                                                            ) => (a = a + b),
                                                            0
                                                        )}
                                                    />
                                                </>
                                            )
                                        )}
                                        <h3>Velika zlata medalja:</h3>
                                        <p className="foreword subtitle">
                                            v skupini 05 Drugi izdelki:
                                        </p>
                                        <h3>
                                            Jetrni cmoki, Mesarija gostinstvo
                                            Lečnik, Tomislav Lečnik s.p.
                                        </h3>
                                        <h2>POSEBNA PRIZNANJA:</h2>
                                        <h3>Šampion kakovosti</h3>
                                        <p className="foreword subtitle">
                                            izdelki s trikratno zaporedno veliko
                                            zlato ali zlato medaljo
                                        </p>
                                        <h3>Kategorija A, B, C</h3>
                                        <Table
                                            data={
                                                statistics.extras
                                                    .specialAwardsABC
                                            }
                                            headers={[
                                                "Naziv izdelka",
                                                "Naziv proizvajalca",
                                            ]}
                                        />
                                        <h3>Kategorija D</h3>
                                        <Table
                                            data={
                                                statistics.extras
                                                    .specialAwardsD
                                            }
                                            headers={[
                                                "Naziv izdelka",
                                                "Naziv proizvajalca",
                                            ]}
                                        />
                                        <h3>Priznanje za inovatorstvo (kategorija B):</h3>
                                        <p className="foreword">Koka d.o.o. Industrija mesa Varaždin</p>
                                        <h3>Priznanje za varovalne mesnine (kategorija C):</h3>
                                        <p className="foreword">Perutnina Ptuj d.o.o.</p>
                                        <h3>Priznanje za tržno ponudbo mesa prejme:</h3>
                                        <p className="foreword">Celjske mesnine d.o.o. : 1 Š (10 točk) + 13 ZMTP (65 točk) + 14 SMTP (42 točk) + 2 BMTP (2 točka) = 119 toč</p>
                                        <h3>Posebno priznanje v letu 2024 je bilo razpisano za hrenovke iz vseh vrst mesa:</h3>
                                        <p className="foreword">Poli cocktail hrenovka, Perutnina Ptuj d.o.o. (najvišje ocenjen izdelek)</p>

                                    </div>
                                );
                            }
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default MesniIzdelkiRecord;
