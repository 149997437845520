import React, { useState } from "react";
import { records } from "./data";
import SubPageImageSection from "../SubPageImageSection";
import { backGroundTitle } from "../Data/dataCategories";
import BackToHome from "../BackToHome";
import Member from "../ProgramCouncil/Member";
import DynamicTable from "./DynamicTable";
import AwardsTable from "./AwardsTable";
import Table from "./Table";
import TwoDTable from "./TwoDTable";
import Stats from "./Stats";

function MedenePijaceRecord() {
    let url = window.location.pathname;
    let category = url.split("/")[2];
    let backLink = url.replace("/record", "");
    const [data, setData] = useState<any>(
        records[category as keyof typeof records]
    );

    return (
        <>
            <SubPageImageSection title={backGroundTitle(url)} year={"2024"} />
            <div className="record-section">
                <BackToHome
                    title={"Nazaj na " + backGroundTitle(backLink)}
                    link={backLink}
                />

                {data.noInfo && <h1 className="no-info">{data.noInfo}</h1>}
                {!data.noInfo && (
                    <>
                        <p className="foreword">{data.foreword}</p>
                        <h1>Ocenjevalna komisija</h1>
                        <h2>Predsednik/ca</h2>
                        <div className="members">
                            {data.president.map(
                                (
                                    president: {
                                        name: string;
                                        position: string;
                                    },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={president.name}
                                        position={president.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>
                        <h2>Člani</h2>
                        <div className="members">
                            {data.members.map(
                                (
                                    member: { name: string; position: string },
                                    index: React.Key | null | undefined
                                ) => (
                                    <Member
                                        key={index}
                                        name={member.name}
                                        position={member.position}
                                    />
                                )
                            )}
                        </div>

                        <div className="divider"></div>
                        {data.technicalLeader && (
                            <>
                                <h2>Tehnični vodja</h2>
                                <Member
                                    name={data.technicalLeader.name}
                                    position={data.technicalLeader.position}
                                />
                            </>
                        )}

                        <h1>
                            Statistični podatki o udeležencih in rezultatih
                            ocenjevanja
                        </h1>
                        {data.statistics.map(
                            (
                                statistics: any,
                                index: React.Key | null | undefined
                            ) => {
                                return (
                                    <div key={index}>
                                        <p className="foreword">
                                            {statistics.description}
                                        </p>
                                        <div className="collaborators">
                                            <DynamicTable
                                                data={statistics.collaborators}
                                                headers={[
                                                    "Država",
                                                    "Število podjetij",
                                                    "Število izdelkov",
                                                ]}
                                            />
                                            <Stats data={statistics} />
                                        </div>
                                        <p className="foreword">
                                            Podeljena odličja
                                        </p>
                                        <TwoDTable
                                            data={statistics.awards}                                                                                      
                                        />                                        
                                    </div>
                                );
                            }
                        )}

                        <h2>Šampion</h2>
                        <p className="foreword subtitle">prejme najvišje ocenjeni vzorec v skupinah, če je v skupini najmanj 10 vzorcev in je vzorec prejel vsaj zlato medaljo:</p>
                        <h3>Ni bil podeljen.</h3>
                        
                    </>
                )}
            </div>
        </>
    );
}

export default MedenePijaceRecord;