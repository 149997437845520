import React from "react";
import champion from "../../image/champion.png";
import grandGold from "../../image/grand-gold.png";
import gold from "../../image/gold.png";
import silver from "../../image/silver.png";
import bronze from "../../image/bronze.png";

interface TableProps {
    data: any;
    headers: string[];
    total: number;
}

const MesoAwardsTable: React.FC<TableProps> = ({ data, headers, total }) => {
    let icons = [champion, grandGold, gold, gold, silver, silver, bronze, bronze];
    return (
        <table className="records-table">
            <thead>
                <tr className="row">
                    {headers.map((header) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.titles.map((title: any, index: number) => (
                    <tr key={index}>
                        <td>
                            <img
                                src={icons[index]}
                                alt="icon"
                                className="icon"
                            />
                            <span>{title}</span>
                        </td>
                        <td>{data.numbers[index]}</td>
                        <td>
                            {((data.numbers[index] / total) * 100).toFixed(2)} %
                        </td>
                    </tr>
                ))}
                <tr style={{ fontWeight: "600" }}>
                    <td>SKUPAJ</td>
                    <td>{total}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    );
};

export default MesoAwardsTable;
